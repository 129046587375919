import React, { useContext,useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsFillPersonFill, BsFillChatDotsFill } from "react-icons/bs";
import { Button,Image } from "react-bootstrap";

import { Context as mainContext } from "../../../../Context/mainContext.js";

const HistoryList = ({ data,userId,reloadPage }) => {
    const {
        state,
        changeReciverId,
        changeConversationId,
        changeBlocked,
        changeBlockUser,
        changeUserName
     } = useContext(mainContext);
    useEffect(()=>{
        changeReciverId(userId)
        changeConversationId(data?.id)
    },[])
    const ReciverId = userId===data?.recipient?.id?data?.sender?.id:data?.recipient?.id;
    const userImage = userId===data?.recipient?.id?data?.sender?.image:data?.recipient?.image;
    const userName = userId===data?.recipient?.id?data?.sender?.name:data?.recipient?.name;
    const changeId = () =>{
        changeReciverId(ReciverId)
        changeConversationId(data?.id)
        data?.recipient?.block==true||data?.sender?.block==true?changeBlocked(true):changeBlocked(false)
        data?.recipient?.block==true?changeBlockUser(true):changeBlockUser(false)
        changeUserName(userName)
        reloadPage()
    }
    const size = 40;
    return (
        <>
        {
            data?.recipient?.id!==data?.sender?.id&&
            <Button onClick={()=>changeId()} variant="outline-dark d-flex justify-content-between align-items-center border-bottom rounded-0 my-2 w-100">
                <div className="d-flex align-items-center">
                {
                    data?.recipient?.image!=null?
                    <Image className="rounded-circle mx-2" style={{objectFit:"cover"}} width={size} height={size} src={userImage}/>:
                    <BsFillPersonFill size={30} />
                }
                    <p className="my-0 mx-2">{userName}</p>
                </div>
                <MdKeyboardArrowRight />
            </Button>
        }
        </>
    )
}

export default HistoryList;