import React,{useContext} from "react";

import Theme from "../../../../styles/theme";
import { Context as mainContext } from "../../../../Context/mainContext";

const SelectCategory = ({ data }) => {
    const { state,addCategoryToSearch } = useContext(mainContext);
    return (
        <>
            {
                data?.childs?.map((item,index)=>{
                    const searchWithCategory=()=>{
                        addCategoryToSearch(item.id)
                    }
                    return(
                        <div key={item?.id} onClick={() => searchWithCategory()} style={{ backgroundColor: Theme.colors.grey, borderColor: item?.id == state?.categorySearch ? Theme.colors.darkGrey : Theme.colors.grey ,borderWidth:2,borderStyle:"solid", cursor: "pointer" }} className="px-3 py-2 mx-3 my-2 rounded-pill">
                            <h6 className="m-0" style={{overFlow: "auto",whiteSpace: "nowrap"}}>{item?.title}</h6>
                        </div>
                    )
                })
            }
        </>
    )
}

export default SelectCategory;