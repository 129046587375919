import React from "react";
import { useTranslation } from 'react-i18next';

import Card from "./component/Card";

const FollowingModal = ({data,CloseModal})=>{
    const { t, i18n } = useTranslation();
    return(
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-between m-2 border-bottom">
                <h3>{t('Following')}</h3>
                <p onClick={()=>CloseModal()} className="my-0" style={{fontSize:30,cursor:"pointer"}}>×</p>
            </div>
            <div style={{maxHeight: 500,overflow:"scroll"}}>
                {
                    data?.following?.length>0?
                    data?.following?.map((item,index)=>{
                        return(
                            <Card key={index} data={item} CloseModal={()=>CloseModal()}/>
                        )
                    }):<h6 className="text-center py-3">{t('no following available')}</h6>
                }
            </div>
        </div>
    )
}

export default FollowingModal;