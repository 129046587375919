import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";

import { Context as mainContext } from "../../Context/mainContext";
import Card from "./component/Card";
import SubCategoryList from "./component/SubCategoryList";
import CenteredSubCategory from "./component/CenteredSubCategory";
import BrandList from "./component/BrandList";
import Theme from "../../styles/theme";
import { useApi } from "../../hooks/useFetch";

import "./Category.scss";

const Category = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = useContext(mainContext);
  const { t, i18n } = useTranslation();
  const [
    getCategories,
    categoriesLevelResponse,
    loadingCategoriesResponse,
  ] = useApi();
  const [getCategory, categoryResponse, loadingCategoryResponse] = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [itemCategoryLevelThree, setItemCategoryLeveThree] = useState([]);
  const [limitedTimeDeals, setLimitedTimeDeals] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const categoryName = location.pathname.split("/")[1];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      getCategories("get", `/en/api/items/category/menu/2`);
      getCategory("get", `/en/api/items/category/${state?.subcategoryId}`);
      try {
        await axios({
          method: "get",
          url: `http://api.ateej.net/en/api/items/category/${state?.subcategoryId}`,
        })
          .then((res) => {
            const response = res.data;
            setLimitedTimeDeals(response?.context);
            setSubCategories(response?.sub_categories);
            console.log({ response });
            setSelectedCategory(response?.menu);
            setBrandsData(response?.brands);
          })
          .catch((error) => {
            console.log({ error });
            setSubCategories([]);
            setSelectedCategory([{ title: state?.subCategoryName }]);
            setBrandsData([]);
            setLimitedTimeDeals([]);
            setIsLoading(false);
            (async () => {
              setIsLoading(true);
              const errorToSend = error?.response?.statusText?.replace(
                / /g,
                "_"
              );
              try {
                await axios({
                  method: "post",
                  url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
                })
                  .then((res) => {
                    // console.log(res);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            })();
          });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [state?.changeCartItems, state?.subcategoryId]);

  useEffect(() => {
    if (categoriesLevelResponse?.length) {
      setItemCategoryLeveThree(categoriesLevelResponse);
    }
  }, [categoriesLevelResponse]);

  useEffect(() => {
    if (categoryResponse?.length) {
      setItemCategoryLeveThree(categoryResponse);
    }
  }, [categoryResponse]);

  if (loadingCategoriesResponse || loadingCategoryResponse) {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <ReactLoading
          type="bubbles"
          height={150}
          width={150}
          color={Theme.colors.darkGrey}
        />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <h2
          style={{ backgroundColor: Theme.colors.lightGreen }}
          className="col-12 col-md-9 py-2 m-0"
        >
          {selectedCategory[0]?.title}
        </h2>
        <div
          style={{ backgroundColor: Theme.colors.darkGreen }}
          className="col-0 col-md-2"
        />
        <div
          style={{ backgroundColor: Theme.colors.semiBlue }}
          className="col-0 col-md-1"
        />
        <div className="d-none d-md-flex my-4 col-12 col-md-3 m-0 mx-auto w-100">
          <div className="mx-2 p-2 border rounded w-100">
            <h6 className="m-0">{t("shop by category")}</h6>
            <div className="my-3">
              {itemCategoryLevelThree?.map((item, index) => {
                return (
                  <div key={item.id}>
                    <p
                      className="p-2 custome_card"
                      style={{ backgroundColor: Theme.colors.grey }}
                    >
                      <b>{item?.title}</b>
                    </p>
                    {item?.childs?.map((subItem, index) => {
                      return (
                        <SubCategoryList
                          data={subItem}
                          rootTitle={item?.title}
                          key={index}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="my-4 col-12 col-md-9 m-0 mx-auto">
          <div className="mx-2 p-2 border rounded">
            <div className="d-flex justify-content-between">
              <h2 className="m-3">{t("Shop by category")}</h2>
              <h6 className="m-3 d-flex justify-content-center align-items-center">
                {t("See all")}
                <span className="mx-2" style={{ fontSize: 28 }}>
                  &#8594;
                </span>
              </h6>
            </div>
            <ScrollContainer
              hideScrollbars={false}
              vertical={false}
              horizontal={true}
              className="scroll-container"
            >
              <div
                style={{ backgroundColor: Theme.colors.lightGrey }}
                className="d-flex"
              >
                {subCategories?.length > 0 ? (
                  subCategories?.map((item) => {
                    return (
                      <CenteredSubCategory
                        rootTitle={categoryName}
                        key={item?.id}
                        data={item}
                      />
                    );
                  })
                ) : (
                  <div
                    style={{ height: 200 }}
                    className="d-flex justify-content-center align-items-center mx-auto"
                  >
                    <h6>{t("no sub category founded")}</h6>
                  </div>
                )}
              </div>
            </ScrollContainer>
            <div className="d-flex justify-content-between">
              <h2 className="m-3">{t("Shop by Brand")}</h2>
            </div>
            <ScrollContainer
              hideScrollbars={false}
              vertical={false}
              horizontal={true}
              className="scroll-container"
            >
              <div
                style={{ backgroundColor: Theme.colors.lightGrey }}
                className="d-flex flex-wrap justify-content-start"
              >
                {brandsData?.length > 0 ? (
                  brandsData?.map((item) => {
                    return <BrandList key={item?.id} data={item} />;
                  })
                ) : (
                  <div
                    style={{ height: 200 }}
                    className="d-flex justify-content-center align-items-center mx-auto"
                  >
                    <h6>{t("no Brands founded")}</h6>
                  </div>
                )}
              </div>
            </ScrollContainer>
            <div className="d-flex justify-content-between">
              <h2 className="m-3">{t("Limited time Deals")}</h2>
            </div>
            <div
              style={{ backgroundColor: Theme.colors.lightGrey }}
              className="d-flex flex-wrap justify-content-start"
            >
              {limitedTimeDeals?.map((item) => {
                return <Card key={item.id} data={item} />;
              })}
            </div>
            {limitedTimeDeals.length == 0 && (
              <div
                style={{
                  backgroundColor: Theme.colors.lightGrey,
                  height: 200,
                }}
                className="d-flex justify-content-center align-items-center"
              >
                <h6>{t("there is no limited Time Deals founded")}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
