import React,{useState,useContext} from "react";
import { useTranslation } from 'react-i18next';
import { Form, Button, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from 'axios';

import {Context as AuthContext} from "../../Context/AuthContext"
import Theme from "../../styles/theme";
import AteejLogo from "../../assets/images/AteejLogo.png"

const VerifyBy =()=>{
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const {
        state,
        changeVerifyType,
        changeVerifyId
        } = useContext(AuthContext);
    const [verifyCode, setVerifyCode] = useState("");
    const token = localStorage.getItem("accessToken");
    const email = localStorage.getItem("email");
    const phone = localStorage.getItem("phone");
    const toggleVerifyType = async() => {
        try {
            await axios({
                method:"post",
                url:`http://api.ateej.net/en/api/auth_user/verify/by/${state?.verifyId}`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data:{email:email}
            }).then(res=>{
                history.push("/Verification")
            }).catch(error=>{
                const errorToSend = error?.response?.statusText.replace(/ /g, "_")
                (async()=>{
                    try {
                        await axios({
                                method:'post',
                                url:`http://api.ateej.net/en/api/logs/web/${errorToSend}`
                            }).then(res => {
                                console.log(res);
                            }).catch(error=>{
                                console.log(error);
                            })   
                        } catch (error) {
                            console.log(error);
                        }
                })()
            })
        } catch (error) {
            console.log(error);
        }
    }
    const VerificationMethods = [
        {
            id:1,
            title: t('send code via SMS')
        },
        {
            id:2,
            title:t('send code via email')
        }
    ]
    const toggleSkipButton = () => {
        history.push("/")
    }
    return(
        <div className="container mb-5">
            <div className="row">
                <div className="col-12 col-md-6 order-1 order-md-0">
                    <h5>{t('Choose Verification method')}</h5>
                    <div className="d-flex card mt-3 p-3">
                        {
                            VerificationMethods?.map((item,index)=>{
                                const toggleVerificationType =(item)=>{
                                    changeVerifyType(item?.title)
                                    changeVerifyId(item?.id)
                                }
                                return(
                                    <div key={item?.id} className="my-1 d-flex flex-column justify-content-center align-items-start">
                                        <Form.Check
                                            className="d-flex my-0"
                                            onClick={()=>toggleVerificationType(item)}
                                            type={'radio'}
                                            name="sameName"
                                            label={`${item?.title}`}
                                            id={`${index}`}
                                        />
                                        <p className="my-0 ml-4">{item?.id===1?phone:email}</p>
                                    </div>
                                )
                            }) 
                        }
                        <Button onClick={() => toggleVerifyType()} variant="primary rounded-0 m-3 px-5">{t('send code')}</Button>
                    </div>
                </div>
                <div className="col-12 col-md-6 order-0 order-md-1 d-flex flex-column align-items-center">
                    <h5>{t('Welcome to Ateej')}</h5>
                    <Image width="200" height="100" style={{objectFit:"contain"}} src={AteejLogo}/>
                </div>
                <div className="col-12 d-flex order-2 justify-content-end mt-3">
                    <Button onClick={()=>toggleSkipButton()} variant="info rounded-0 px-5">{t('Skip')}</Button>
                </div>
            </div>
        </div>
    )
}

export default VerifyBy;