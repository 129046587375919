import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";

import { Context as mainContext } from "../../../Context/mainContext";

const Menu = ({ item, setTitle }) => {
  const { addCategoryToSearch } = useContext(mainContext);
  const setSearchCategory = () => {
    addCategoryToSearch(item.id);
    setTitle();
  };

  return (
    <Dropdown.Item onClick={() => setSearchCategory()} style={{ fontSize: 12 }}>
      {item.title}
    </Dropdown.Item>
  );
};
export default Menu;
