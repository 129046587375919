import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ReactLoading from "react-loading";
import {
  Accordion,
  Button,
  Dropdown,
  FormControl,
  Form,
} from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Card from "./component/Card";
import SelectPrices from "./component/SelectPrices";
import SelectCategory from "./component/SelectCategory";
import SelectCondition from "./component/SelectCondition";
import SelectSorting from "./component/SelectSorting";
import { Context as mainContext } from "../../Context/mainContext";
import Theme from "../../styles/theme";
import "./Search.scss";

const Search = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [data, setData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [responseError, setResponseError] = useState({});
  const [countrySelected, setCountrySelected] = useState({
    title: t("all"),
    id: 0,
  });
  const [dropdownButton, setDropdownButton] = useState(t("more than"));
  const [priceInput, setPriceInput] = useState(null);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [barCode, setBarCode] = useState(0);
  const {
    state,
    addPriceToSearch,
    addCategoryToSearch,
    addConditionToSearch,
    addSortingToSearch,
    pushSearchFunction,
  } = useContext(mainContext);
  const emptyArray = [];
  const Prices = [
    {
      id: 0,
      title: t("more than"),
    },
    {
      id: 1,
      title: t("less than"),
    },
  ];
  const conditionData = [
    {
      id: 0,
      title: t("any"),
    },
    {
      id: 1,
      title: t("new"),
    },
    {
      id: 2,
      title: t("used"),
    },
  ];
  const sortingData = [
    {
      id: 0,
      title: t("Best Match"),
    },
    {
      id: 1,
      title: t("Lowest Price"),
    },
    {
      id: 2,
      title: t("Highest Price"),
    },
    {
      id: 3,
      title: t("Newly Listed"),
    },
  ];
  // var url = "http://blablab/test/page.php";
  // alert(url.split("/")[3]);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await axios
          .get(
            `http://api.ateej.net/en/api/items/search/all/${
              state?.categorySearch
            }/${minPrice === "" ? 0 : minPrice}/${
              maxPrice === "" ? 0 : maxPrice
            }/${countrySelected?.id}/${state?.conditionSearch}/${
              barCode == null ? 0 : barCode
            }/${state?.sortingSearch}/${state?.textSearch}`
          )
          .then((res) => {
            const searchResult = res?.data?.context;
            setData(searchResult);
            setIsLoading(false);
          })
          .catch((error) => {
            setData(emptyArray);
            console.log(error);
          });
        await axios
          .get(`http://api.ateej.net/en/api/items/menu/3`)
          .then((res) => {
            const categoriesResponse = res?.data?.context;
            setCategoriesData(categoriesResponse);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            const errorToSend = error?.response?.statusText?.replace(
              / /g,
              "_"
            )(async () => {
              await axios({
                method: "post",
                url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
              })
                .then((res) => {
                  console.log(res);
                })
                .catch((error) => {
                  console.log(error);
                });
            })();
          });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [state?.textSearch, state?.searchFucntion]);
  useEffect(() => {
    (async () => {
      try {
        await axios({
          method: "get",
          url: `http://api.ateej.net/en/api/countries/`,
        })
          .then((res) => {
            setCountriesData(res.data?.context);
          })
          .catch((error) => {
            setIsLoading(false);
            const errorToSend = error?.response?.statusText?.replace(
              / /g,
              "_"
            )(async () => {
              await axios({
                method: "post",
                url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
              })
                .then((res) => {
                  console.log(res);
                })
                .catch((error) => {
                  console.log(error);
                });
            })();
          });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const changeInputPrice = (value) => {
    setPriceInput(value);
    state?.priceSearch == 0 ? setMinPrice(value) : setMaxPrice(value);
  };

  const changeMinimumValue = () => {
    setMinPrice(priceInput);
    setMaxPrice(0);
  };

  const changeMaximumValue = () => {
    setMinPrice(0);
    setMaxPrice(priceInput);
  };

  const searchWithPrice = async (e, id) => {
    e === "more than" ? changeMinimumValue() : changeMaximumValue();
    addPriceToSearch(id);
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <p
      className="px-3 py-1 rounded-pill"
      style={{
        cursor: "pointer",
        backgroundColor: Theme.colors.grey,
        borderColor: Theme.colors.darkGrey,
        borderWidth: 2,
        borderStyle: "solid",
      }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="ml-2" style={{ fontSize: 12 }}>
        &#x25bc;
      </span>
    </p>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          {/* <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder={t("Type to filter Countries")}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          /> */}
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  const searchWithAllCategories = () => {
    addCategoryToSearch(0);
  };
  // console.log(`http://api.ateej.net/en/api/items/search/all/${state?.categorySearch}/${minPrice===""?0:minPrice}/${maxPrice===""?0:maxPrice}/${countrySelected?.id}/${state?.conditionSearch}/${barCode==null?0:barCode}/${state?.sortingSearch}/${state?.textSearch}`);
  // console.log("state?.categorySearch",state?.categorySearch);
  // console.log("minPrice",minPrice===""?0:1);
  const changeCountryToAll = () => {
    setCountrySelected({ title: t("all"), id: 0 });
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      pushSearchFunction();
    }
  };
  console.log({ categoriesData, countrySelected });
  return (
    <>
      {isLoading === true ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ReactLoading
            type="bubbles"
            height={150}
            width={150}
            color={Theme.colors.darkGrey}
          />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div
              style={{ overflowX: "scroll" }}
              className="col-12 col-md-2 d-flex flex-row flex-md-column border rounded m-0 m-md-4 p-2"
            >
              {categoriesData?.map((item, index) => {
                return (
                  <Accordion key={index} defaultActiveKey="0">
                    <Accordion.Toggle
                      as={Button}
                      style={{ width: 170 }}
                      variant="link text-left"
                      eventKey="1"
                    >
                      {item.title}
                      <span>
                        <RiArrowDropDownLine size={15} />
                      </span>
                    </Accordion.Toggle>
                    {item?.childs?.length
                      ? item?.childs.map((item, index) => {
                          return (
                            <Accordion.Collapse key={index} eventKey="1">
                              <p
                                className="my-2 ml-3"
                                style={{ fontSize: 12, cursor: "pointer" }}
                              >
                                {item.title}
                              </p>
                            </Accordion.Collapse>
                          );
                        })
                      : null}
                  </Accordion>
                );
              })}
            </div>
            <div className="col-12 col-md-9 row d-flex justify-content-between mx-auto">
              <div className="col-12 col-md-6 p-3">
                <div className="border rounded p-3">
                  <h6>{t("Price")}</h6>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-around">
                      {Prices.map((item, index) => {
                        return (
                          <div
                            onClick={() => searchWithPrice(item.title, item.id)}
                            className="d-flex justify-content-between align-items-center px-3 py-2 mx-3 my-2 rounded-pill"
                            style={{
                              backgroundColor: Theme.colors.grey,
                              borderColor:
                                item.id == state?.priceSearch
                                  ? Theme.colors.darkGrey
                                  : Theme.colors.grey,
                              borderWidth: 2,
                              borderStyle: "solid",
                              cursor: "pointer",
                            }}
                          >
                            <h6
                              className="m-0 p-0"
                              style={{ overFlow: "auto", whiteSpace: "nowrap" }}
                            >
                              {t(`${item.title}`)}
                            </h6>
                          </div>
                        );
                      })}
                    </div>
                    <Form.Control
                      value={priceInput}
                      onKeyPress={handleKeypress}
                      onChange={(e) => changeInputPrice(e.target.value)}
                      className="border-top-0 border-left-0 border-right-0 rounded-0 py-2 pl-1 mx-2 my-2 my-md-0"
                      type="number"
                      placeholder={t("price")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 p-3">
                <div className="border rounded p-3">
                  <h6>{t("Category")}</h6>
                  <div className="d-flex" style={{ overflowX: "auto" }}>
                    <div
                      onClick={() => searchWithAllCategories()}
                      style={{
                        backgroundColor: Theme.colors.grey,
                        borderColor:
                          state?.categorySearch === 0
                            ? Theme.colors.darkGrey
                            : Theme.colors.grey,
                        borderWidth: 2,
                        borderStyle: "solid",
                        cursor: "pointer",
                      }}
                      className="px-3 py-2 mx-3 my-2 rounded-pill"
                    >
                      <h6
                        className="m-0"
                        style={{ overFlow: "auto", whiteSpace: "nowrap" }}
                      >
                        {t("All Categories")}
                      </h6>
                    </div>
                    {categoriesData.map((item, index) => {
                      return <SelectCategory key={item.id} data={item} />;
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 p-3">
                <div className="border rounded p-3 w-100">
                  <h6>{t("Condition")}</h6>
                  <div className="d-flex w-100" style={{ overflowX: "auto" }}>
                    {conditionData.map((item, index) => {
                      return <SelectCondition key={item.id} data={item} />;
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 p-3">
                <div className="border rounded p-3">
                  <h6>{t("Sorting")}</h6>
                  <div className="d-flex" style={{ overflowX: "auto" }}>
                    {sortingData.map((item, index) => {
                      return <SelectSorting key={item.id} data={item} />;
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="border rounded p-3">
                  <h6>{t("Countries")}</h6>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-components"
                    >
                      {countrySelected?.title}
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                      <div
                        style={{
                          maxHeight: 150,
                          overflowY: "auto",
                        }}
                      >
                        <Dropdown.Item onClick={() => changeCountryToAll()}>
                          {t("all")}
                        </Dropdown.Item>
                        {countriesData.map((item, index) => {
                          const selectValue = () => {
                            setCountrySelected(item);
                          };
                          return (
                            <Dropdown.Item
                              onClick={() => selectValue()}
                              key={item?.id}
                              eventKey={index + 1}
                            >
                              {item?.title}
                            </Dropdown.Item>
                          );
                        })}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-12 col-md-6 p-3">
                <div className="border rounded p-3">
                  <h6>{t("Bar Code")}</h6>
                  <div className="d-flex" style={{ overflowX: "auto" }}>
                    <input
                      value={barCode}
                      onChange={(e) => setBarCode(e.target.value)}
                      className="border-top-0 border-left-0 border-right-0 border-bottom rounded-0 py-2 pl-1 mx-2 my-2 my-md-0"
                      placeholder={t("bar code")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                {data.map((item, index) => {
                  return <Card data={item} key={item.id} />;
                })}
                {data.length == 0 && (
                  <h6 className="p-3">
                    {t("No result found for your search try another thing")}
                  </h6>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Search;
