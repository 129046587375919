import React, { useEffect, useState, useContext } from "react";
import ReactLoading from "react-loading";

import Theme from "../../styles/theme";
import FooterContainer from "../../component/FooterContainer/FooterContainer";
import MainHeader from "../../component/headerContainer/mainHeader";
import SearchContainer from "../../component/headerContainer/SearchContainer";

const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, []);
  return (
    <>
      {isLoading === true ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ReactLoading
            type="bubbles"
            height={150}
            width={150}
            color={Theme.colors.darkGrey}
          />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <h1>Notifications</h1>
          </div>
        </div>
      )}
    </>
  );
};
export default Notifications;
