import React,{useState} from "react";
// import { Image, Modal, Button, Form, Accordion } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import PhoneInput from "react-phone-input-2";

const AddItemForm =()=>{
    const [phoneNumber, setPhoneNumber]=useState(null);
    const [phoneNumberError, setPhoneNumberError]=useState("");
    const { t, i18n } = useTranslation();
    var formData = new FormData();
    const { handleSubmit, register, errors } = useForm();

    const onSubmit =async(values)=>{
        console.log(values);
    }

    return(
        <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex flex-column">
                <div className="d-flex justify-content-between m-2">
                    <h3>{t('Add new item')}</h3>
                    <p className="my-0" style={{fontSize:30}}>×</p>
                </div>
                <p className="my-0" style={{ fontSize: 18 }}>{t('name')}</p>
                    <input
                        name="name"
                        ref={register({
                            required: "Required",
                        })}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-3"
                        placeholder={t('name')}
                    />
                    <p style={{ color: "red", fontSize: 12 }}>
                    {errors.name && errors.name.message}
                    </p>
                <p className="my-0" style={{ fontSize: 18 }}>{t('description')}</p>
                    <input
                    name="description"
                        ref={register({
                            required: "Required",
                        })}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-3"
                    placeholder={t('description')}
                    />
                    <p style={{ color: "red", fontSize: 12 }}>
                    {errors.description && errors.description.message}
                    </p>
                <p className="my-0" style={{ fontSize: 18 }}>{t('price')}</p>
                    <input
                    name="price"
                        ref={register({
                            required: "Required",
                        })}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-2"
                        placeholder={t('price')}
                    />
                    <p style={{ color: "red", fontSize: 12 }}>
                    {errors.price && errors.price.message}
                    </p>
                <p className="my-0" style={{ fontSize: 18 }}>{t('Condition')}</p>
                    <input
                    name="Condition"
                        ref={register({
                            required: "Required",
                            minLength: {
                                value: 6,
                                message: "Password must have at least 6 characters",
                            },
                        })}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-2"
                    placeholder={t('Condition')}
                    />
                    <PhoneInput
                        buttonClass="rounded-0"
                        inputClass="w-100 rounded-0 border-top-0 border-right-0 border-left-0"
                        name="phoneNumber"
                        type="text"
                        country={"kw"}
                        enableAreaCodes={true}
                        areaCodes={{ us: ["332"] }}
                        inputProps={{
                        name: "phone",
                        country: "us",
                        required: true,
                        autoFocus: true
                        }}
                        value={phoneNumber}
                        onChange={(value)=>setPhoneNumber(value)}
                        required
                    />
                    <p className="p-3" style={{ color: "red", fontSize: 12 }}>{phoneNumberError}</p>
                    <input ref={register} type="file" name="picture"/>
                    <button className="btn btn-primary rounded-0 my-2">Submit</button>
                </div>
                </form>
    )
}

export default AddItemForm;