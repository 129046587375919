import React, { useContext } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { Context as mainContext } from "../../../../../../../Context/mainContext";

const Card = ({ data, rootTitle }) => {
  const { changeSubCategoryId, changeSubCategoryName } = useContext(
    mainContext
  );
  const history = useHistory();
  const categoryTitle = data?.title?.replace(/ /g, "_");
  const roottitle = rootTitle?.replace(/ /g, "_");
  const navigateToCategoryDetails = () => {
    history.push({
      pathname: `/${roottitle}/${categoryTitle}`,
      state: {
        _subCategoryId: data?.id,
      },
    });
    changeSubCategoryId(data?.id);
    changeSubCategoryName(data?.title);
  };
  return (
    <a
      onClick={() => navigateToCategoryDetails()}
      className="d-flex justify-content-center align-items-center"
      style={{ color: "inherit" }}
    >
      <p className="my-1" style={{ fontWeight: "bold" }}>
        {data?.title}
      </p>
      <MdKeyboardArrowRight className="align-self-center" />
    </a>
  );
};

export default Card;
