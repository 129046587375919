import React,{useContext} from "react";
import { useHistory } from "react-router-dom"
import { Image } from "react-bootstrap";

import {Context as mainContext} from "../../../../Context/mainContext";
import Theme from "../../../../styles/theme";

const Card =({data})=>{
    const {addBrandId} = useContext(mainContext);
    const history = useHistory();
    const navigateToBrandDetails = () => {
        history.push({
            pathname: `/Brands/${data.title}`,
            state: {
                _id: data.id,
                _name:data.title
            }
        })
        addBrandId(data.id)
    }
    return(
        <div onClick={()=>navigateToBrandDetails()} className="rounded-circle d-flex flex-column justify-content-center align-items-center mx-2 my-3 my-md-0" 
            style={{ 
                backgroundColor: Theme.colors.darkGrey,
                height:200,
                width:200,
                cursor:"pointer"
            }}
        >
            <p className="my-0 p-3"style={{ cursor: "pointer",fontSize:18,color:Theme.colors.white }}>{data?.title}</p>
            {
                data?.image!=null?
                <Image width="100" height="100" src={data?.image}/>:data?.file!=null&&
                <Image width="100" height="100" src={data?.file}/>
            }
        </div>
    )
}
export default Card;