import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18next"
// import axios from "axios";
// import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';

// axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang')||'en';

ReactDOM.render(
  <Suspense fallback={(<div>Loading~~~</div>)}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.unregister();
