import React,{useContext} from "react";

import Theme from "../../../../styles/theme";
import { Context as mainContext } from "../../../../Context/mainContext";

const SelectCondition =({data})=>{
    const { state,addConditionToSearch } = useContext(mainContext);
    const searchWithCondition=()=>{
        addConditionToSearch(data.id)
    }
    return(
        <div onClick={() => searchWithCondition()} style={{ backgroundColor: Theme.colors.grey, borderColor: data.id == state?.conditionSearch ? Theme.colors.darkGrey : Theme.colors.grey ,borderWidth:2,borderStyle:"solid", cursor: "pointer" }} className="px-3 py-2 mx-3 my-2 rounded-pill">
            <h6 className="m-0 px-3">{data.title}</h6>
        </div>
    )
}

export default SelectCondition;