import React,{useContext} from "react";
import {Image} from "react-bootstrap";

import Theme from "../../../../styles/theme";
import {Context as mainContext} from "../../../../Context/mainContext";

const PaymentCard = ({data}) =>{
    const {state,changePaymentMethod} = useContext(mainContext);
    const togglePayment =()=>{
        changePaymentMethod(data?.id)
    }
    return(
        <div onClick={()=>togglePayment()} className="rounded mx-2 p-2" style={{backgroundColor:Theme.colors.white,borderColor:state?.paymentMethod==data?.id?Theme.colors.paymentBorderColor:Theme.colors.white,borderStyle:"solid",borderWidth:2,cursor:"pointer"}}>
            <Image width="100" style={{objectFit:"contain"}} src={data?.logo}/>
        </div>
    )
}

export default PaymentCard;