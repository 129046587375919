import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyB46UzavN7Fw17kogGY4bJ-WRwnJgd-lFw",
    authDomain: "ateej-18e9a.firebaseapp.com",
    projectId: "ateej-18e9a",
    storageBucket: "ateej-18e9a.appspot.com",
    messagingSenderId: "845305672880",
    appId: "1:845305672880:web:e3e7852af7444b3a67f775",
    measurementId: "G-1PNM0S0EX9"
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
// this next line just to make popup whenever user click on signin with google and facebook
googleProvider.setCustomParameters({ prompt: 'select_account' })
facebookProvider.setCustomParameters({ prompt: 'select_account' })

export const signinWithGoogle = () => auth.signInWithPopup(googleProvider);
export const signinWithFacebook = () => auth.signInWithPopup(facebookProvider);

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;
    const userRef = firestore.doc(`users/${userAuth.uid}`)
    const snapShot = await userRef.get();
    if (!snapShot.exists) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();
        try {
            await userRef.set({
                displayName,
                email,
                createdAt,
                ...additionalData
            })
        } catch (error) {
            console.log("error", error.message);
        }
    }
    return userRef;
}

export default firebase;