import createDataContext from "./createDataContext";

const inialState = {
  refreshHome: 0,
  cartItems: [],
  changeCartItems: false,
  searchFucntion: false,
  textSearch: "",
  brandId: 0,
  priceSearch: 0,
  categorySearch: 0,
  subCategoryName: "",
  conditionSearch: 0,
  sortingSearch: 0,
  reciverId: null,
  conversationId: null,
  blockedUser: false,
  showMessageContainer: false,
  blockUser: false,
  username: "",
  subcategoryId: null,
  itemId: null,
  paymentMethod: null,
  countryId: null,
  firstQuestionAnswer: null,
  secondQuestionAnswer: null,
  thirdQuestionAnswer: null,
};

const authReducer = (state = inialState, action) => {
  switch (action.type) {
    case "REFRESH_HOME":
      return { ...state, refreshHome: action.payload };
    case "SET_SEARCH_TEXT":
      return { ...state, textSearch: action.payload };
    case "CHANGE_CART_ITEMS":
      return { ...state, changeCartItems: !state.changeCartItems };
    case "PUSH_SEARCH_FUNCTION":
      return { ...state, searchFucntion: !state.searchFucntion };
    case "ADD_TO_CART":
      var cartItems = state.cartItems;
      cartItems.push(action.payload);
      var items = {
        ...state,
        cartItems: cartItems,
      };
      return items;
    case "ADD_BRAND_ID":
      return { ...state, brandId: action.payload };
    case "ADD_PRICE_SEARCH":
      return {
        ...state,
        priceSearch:
          state.priceSearch == action.payload ? null : action.payload,
      };
    case "ADD_CATEGORY_SEARCH":
      return {
        ...state,
        categorySearch:
          state.categorySearch == action.payload ? 0 : action.payload,
      };
    case "CHANGE_SUBCATEGORY_ID":
      return { ...state, subcategoryId: action.payload };
    case "CHANGE_SUBCATEGORY_NAME":
      return { ...state, subCategoryName: action.payload };
    case "ADD_CONDITION_SEARCH":
      return {
        ...state,
        conditionSearch:
          state.conditionSearch == action.payload ? 0 : action.payload,
      };
    case "ADD_SORTING_SEARCH":
      return {
        ...state,
        sortingSearch:
          state.sortingSearch == action.payload ? 0 : action.payload,
      };
    case "CHANGE_PAYMENT_METHOD":
      return {
        ...state,
        paymentMethod:
          state.paymentMethod == action.payload ? null : action.payload,
      };
    case "CHANGE_COUNTRY_ID":
      return {
        ...state,
        countryId: state.countryId == action.payload ? null : action.payload,
      };
    case "CHANGE_RECIVER_ID":
      return { ...state, reciverId: action.payload };
    case "CHANGE_CONVERSATION_ID":
      return { ...state, conversationId: action.payload };
    case "CHANGE_BLOCKED_USER":
      return { ...state, blockedUser: action.payload };
    case "SHOW_MESSAGES_CONTAINER":
      return { ...state, showMessageContainer: action.payload };
    case "CHANGE_BLOCK_USER":
      return { ...state, blockUser: action.payload };
    case "CHANGE_USER_NAME":
      return { ...state, username: action.payload };
    case "CHANGE_ITEM_ID":
      return { ...state, itemId: action.payload };
    case "CHANGE_FIRST_QUESTION_ANSWER":
      return {
        ...state,
        firstQuestionAnswer:
          state?.firstQuestionAnswer === action.payload ? null : action.payload,
      };
    case "CHANGE_SECOND_QUESTION_ANSWER":
      return {
        ...state,
        secondQuestionAnswer:
          state?.secondQuestionAnswer === action.payload
            ? null
            : action.payload,
      };
    case "CHANGE_THIRD_QUESTION_ANSWER":
      return {
        ...state,
        thirdQuestionAnswer:
          state?.thirdQuestionAnswer === action.payload ? null : action.payload,
      };
    default:
      return state;
  }
};

const refreshHome = (dispatch) => async () => {
  dispatch({ type: "REFRESH_HOME", payload: Math.random(12024) * 0.01 });
};

const addtocart = (dispatch) => async (data) => {
  dispatch({ type: "ADD_TO_CART", payload: data });
};

const addBrandId = (dispatch) => async (data) => {
  dispatch({ type: "ADD_BRAND_ID", payload: data });
};

const searchText = (dispatch) => async (data) => {
  dispatch({ type: "SET_SEARCH_TEXT", payload: data });
};

const changeCartItems = (dispatch) => async () => {
  dispatch({ type: "CHANGE_CART_ITEMS" });
};

const addPriceToSearch = (dispatch) => async (data) => {
  dispatch({ type: "ADD_PRICE_SEARCH", payload: data });
};

const addCategoryToSearch = (dispatch) => async (data) => {
  dispatch({ type: "ADD_CATEGORY_SEARCH", payload: data });
};

const addConditionToSearch = (dispatch) => async (data) => {
  dispatch({ type: "ADD_CONDITION_SEARCH", payload: data });
};

const addSortingToSearch = (dispatch) => async (data) => {
  dispatch({ type: "ADD_SORTING_SEARCH", payload: data });
};

const changePaymentMethod = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_PAYMENT_METHOD", payload: data });
};

const changeCountryId = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_COUNTRY_ID", payload: data });
};

const changeReciverId = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_RECIVER_ID", payload: data });
};

const changeConversationId = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_CONVERSATION_ID", payload: data });
};

const changeBlocked = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_BLOCKED_USER", payload: data });
};

const showMessagesContainer = (dispatch) => async (data) => {
  dispatch({ type: "SHOW_MESSAGES_CONTAINER", payload: data });
};

const changeBlockUser = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_BLOCK_USER", payload: data });
};

const changeUserName = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_USER_NAME", payload: data });
};

const changeSubCategoryId = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_SUBCATEGORY_ID", payload: data });
};

const changeSubCategoryName = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_SUBCATEGORY_NAME", payload: data });
};

const changeItemId = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_ITEM_ID", payload: data });
};

const pushSearchFunction = (dispatch) => async (data) => {
  dispatch({ type: "PUSH_SEARCH_FUNCTION", payload: data });
};

const changeFirstQuestionAnswer = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_FIRST_QUESTION_ANSWER", payload: data });
};

const changeSecondQuestionAnswer = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_SECOND_QUESTION_ANSWER", payload: data });
};

const changeThirdQuestionAnswer = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_THIRD_QUESTION_ANSWER", payload: data });
};

const setReviewsData = (dispatch) => async (data) => {
  console.log({ setReviewsData: data });
  dispatch({ type: "CHANGE_THIRD_QUESTION_ANSWER", payload: data });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    refreshHome,
    addtocart,
    addBrandId,
    changeCartItems,
    searchText,
    addPriceToSearch,
    addCategoryToSearch,
    changeSubCategoryId,
    changeSubCategoryName,
    addConditionToSearch,
    addSortingToSearch,
    changeReciverId,
    changeConversationId,
    changeBlocked,
    showMessagesContainer,
    changeBlockUser,
    changeUserName,
    changeItemId,
    changePaymentMethod,
    changeCountryId,
    pushSearchFunction,
    changeFirstQuestionAnswer,
    changeSecondQuestionAnswer,
    changeThirdQuestionAnswer,
    setReviewsData,
  },
  inialState
);
