import React,{useState,useEffect,useContext} from "react";
import axios from "axios";
import ReactLoading from 'react-loading';
import { useHistory,useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {Button,Form,InputGroup,FormControl,Dropdown} from "react-bootstrap";
import { BsFillChatDotsFill } from "react-icons/bs";
import { IoIosSend } from "react-icons/io";
import { FiMoreVertical } from "react-icons/fi";

import HistoryList from "./component/HistoryList";
import MessagesContainer from "./component/MessagesContainer";
import Theme from "../../styles/theme";
import {Context as mainContext} from "../../Context/mainContext.js";
import "./Contact.scss"
const Contact =()=>{
    const { state,changeBlocked,changeUserName } = useContext(mainContext);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const userId = location.state;
    const [conversationHistory,setConversationHistory]=useState([]);
    const [isLoading,setIsLoading]=useState(false);
    const [loadSendMessage,setLoadSendMessage]=useState(false);
    const [reloadMessages,setReloadMessages]=useState(false);
    const [message,setMessage]=useState("");
    const [block,setBlock] = useState(false);
    const token = localStorage.getItem("accessToken");
    var bodyFormData = new FormData();
    useEffect(()=>{
        (async()=>{
            setIsLoading(false)
            axios({
                method:"get",
                url:`http://ateej.net/en/api/conversations/`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            }).then(res=>{
                const historyConversation = res.data;
                setConversationHistory(historyConversation);
                const findUser = historyConversation?.context.find(item=>item?.sender?.id!==item?.recipient?.id&&item?.recipient?.id||item?.sender?.id===state?.reciverId);
                console.log("findUser",findUser);
                changeUserName(state?.reciverId===findUser?.recipient?.id?findUser?.recipient?.name:findUser?.sender?.name);
                findUser?.recipient?.block==true||findUser?.sender?.block==true?setBlock(true):setBlock(false);
                setIsLoading(false);
            }).catch(error=>{
                console.log(error);
                setIsLoading(false);
            })
        })()
    },[reloadMessages])
    const sendMessage =async()=>{
        setLoadSendMessage(true);
        bodyFormData.append('body', message);
        try {
        await axios({
            method:"post",
            url:`http://ateej.net/en/api/conversations/new/${state?.reciverId}`,
            data: bodyFormData,
            headers: {
                "Authorization": `Bearer ${token}`,
                'content-type': 'multipart/form-data'
            }
        }).then(res=>{
            setMessage("");
            console.log(res.data);
        }).catch(error=>{
            console.log("error",error?.response);
        })
        setLoadSendMessage(false);
        setReloadMessages(!reloadMessages)
        } catch (error) {
        setLoadSendMessage(false);
            console.log("trycatcherror",error);
        }
    }
    const handleKeypress = e => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };
    const navigateToUserProfile =()=>{
        history.push({
            pathname: `/User/${state?.username}`,
            state: {
                _id: state?.reciverId,
            }
        })
    }
    const blockUnblockUser= async()=>{
        setLoadSendMessage(true);
        await axios({
            method:"post",
            url:`http://ateej.net/en/api/conversations/block/${state?.conversationId}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res=>{
            console.log(res);
            setLoadSendMessage(false);
            setReloadMessages(!reloadMessages)
        }).catch(error=>{
            console.log(error);
            setLoadSendMessage(false);
        })
    }
    const blockText = t('block')
    const unBlockText=t('Unblock')
    return(
        <>
            {
                isLoading === true ? <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                    <ReactLoading type="bubbles" height={150} width={150} color={Theme.colors.darkGrey} /></div> :
                    <div className="container-fluid h-100 mb-5">
                        <div className="row">
                            <div className="col-3">
                                <h6>{t('chat history')}</h6>
                                <Form.Control type="text" placeholder="search" />
                                <div>
                                    {
                                        conversationHistory?.context?.map((item,index)=>{
                                            return(
                                                <HistoryList key={item.id} data={item} userId={userId?._id} reloadPage={()=>setReloadMessages(!reloadMessages)}/>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-9">
                                <h6>{t('start messages')}</h6>
                                <div className="card p-3">
                                    <div className="d-flex justify-content-between border-bottom">
                                        <h3 onClick={()=>navigateToUserProfile()} style={{cursor:"pointer"}}>{state?.username}</h3>
                                        <Dropdown>
                                            <Dropdown.Toggle style={{width:40,height:40}} variant="light d-flex justify-content-center align-items-center rounded-circle" id="dropdown-basic">
                                                <FiMoreVertical style={{color:"#000"}}/>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={()=>blockUnblockUser()}>{block===true?unBlockText:blockText}</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>navigateToUserProfile()}>{t('visit profile')}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div style={{maxHeight: 200,overflowY: 'auto'}} className="d-flex flex-column mt-2">
                                        <MessagesContainer userId={userId?._id} reload={reloadMessages}/>
                                    </div>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1" style={{cursor:"pointer"}} onClick={()=>sendMessage()}>
                                        {
                                            loadSendMessage===true?<ReactLoading type="spinningBubbles" height={20} width={20} color={Theme.colors.darkGrey} />:
                                            <IoIosSend size={20}/>
                                        }
                                        </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control onKeyPress={handleKeypress} disabled={block} value={message} onChange={(e)=>setMessage(e.target.value)} type="text" placeholder="write message" />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Contact;