import React from "react";
import { Provider as AuthProvider } from "./Context/AuthContext";
import { Provider as HomeProvider } from "./Context/mainContext";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import "./App.css";

import Home from "./pages/home";
import Login from "./pages/login/login";
import SignUp from "./pages/signup/signup";
import VerifyBy from "./pages/VerifyBy";
import Verification from "./pages/Verification";
import Category from "./pages/Category";
import Brands from "./pages/Brands";
import BrandsDetails from "./pages/BrandsDetails";
import ItemDetails from "./pages/itemDetails/itemDetails";
import RatingAndReviews from "./pages/RatingAndReviews";
import Cart from "./pages/cart/cart";
import Notifications from "./pages/notifications/Notifications";
import Checkout from "./pages/checkout/checkout";
import CheckoutItem from "./pages/checkoutItem/CheckoutItem";
import Profile from "./pages/profile/Profile";
import Search from "./pages/Search/Search";
import Contact from "./pages/Contact/Contact";
import NotFound from "./pages/NotFound";
import ProfileToVisit from "./pages/ProfileToVisit";

import MainHeader from "./component/headerContainer/mainHeader";
import SearchContainer from "./component/headerContainer/SearchContainer";
import FooterContainer from "./component/FooterContainer/FooterContainer";

import AteejLogo from "./assets/images/AteejLogo.png";

const App = () => {
  return (
    <HomeProvider>
      <AuthProvider>
        <div className="container-fluid h-100">
          <div className="row">
            <div className="col-12">
              <MainHeader />
              <SearchContainer img={AteejLogo} />
            </div>
          </div>
        </div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/VerifyBy" component={VerifyBy} />
          <Route exact path="/Verification" component={Verification} />
          <Route exact path="/Brands" component={Brands} />
          <Route exact path="/Brands/:brandName" component={BrandsDetails} />
          <Route exact path="/Cart" component={Cart} />
          <Route exact path="/Notifications" component={Notifications} />
          <Route exact path="/Checkout" component={Checkout} />
          <Route exact path="/CheckoutItem" component={CheckoutItem} />
          <Route exact path="/Profile/:username" component={Profile} />
          <Route exact path="/Search" component={Search} />
          <Route exact path="/Contact/:username" component={Contact} />
          <Route exact path="/User/:username" component={ProfileToVisit} />
          <Route
            exact
            path="/:categoryName/:subcategoryName"
            component={Category}
          />
          <Route exact path="/:categoryName" component={Category} />
          <Route
            exact
            path="/:categoryName/:subcategoryName/:itemId"
            component={ItemDetails}
          />
          <Route
            exact
            path="/:categoryName/:subcategoryName/:itemId/Reviews"
            component={RatingAndReviews}
          />
          <Route component={NotFound} />
          <Route exact path="/NotFound" component={NotFound} />
        </Switch>
        <FooterContainer />
      </AuthProvider>
    </HomeProvider>
  );
};

export default App;
