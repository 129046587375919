import React, { useEffect, useState, useContext } from "react";
import ReactLoading from "react-loading";
import axios from "axios";
import { Image, Modal, Button, Form, Accordion } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import {
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineStar,
  AiFillStar,
  AiFillEdit,
  AiFillSetting,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { BsFillChatDotsFill } from "react-icons/bs";
import Rating from "react-rating";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useForm } from "react-hook-form";
import SweetAlert from "react-bootstrap-sweetalert";

import Cover from "../../assets/images/cover.png";
import FullConfirmed from "../../assets/images/fullConfirmed.png";
import BadgeConfirmed from "../../assets/images/badge_confirmed.png";
import BadgeIdentityTypes from "../../assets/images/badge_identity_types.png";
import { Context as MainContext } from "../../Context/mainContext";
import Card from "./component/Card";
import AddItemForm from "./component/AddItemForm";
import AddAddressModal from "./component/AddAddressModal";
import AdressesModal from "./component/AdressesModal";
import EditAdressModal from "./component/EditAdressModal";
import EditUserDataModal from "./component/EditUserDataModal";
import FollowingModal from "../ProfileToVisit/component/FollowingModal";
import FollowersModal from "../ProfileToVisit/component/FollowersModal";
import Theme from "../../styles/theme";

const Profile = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = useContext(MainContext);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [refreshPage, setRefreshPage] = useState(false);
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [adressesModal, setAdressesModal] = useState(false);
  const [addAdressesModal, setAddAdressesModal] = useState(false);
  const [specificAddress, setSpecificAddress] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [reloadfollow, setReloadfollow] = useState(false);
  const [password, setPassword] = useState("");
  const [personData, setPersonData] = useState({});
  const [addressesData, setAddressesData] = useState([]);
  const [checkoutItems, setCheckoutItems] = useState([]);
  const [responseError, setResponseError] = useState({});
  const [accessToken, setAccessToken] = useState("");
  const [images, setImages] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [itemAdded, setItemAdded] = useState(false);
  const [errorAddItems, setErrorAddItems] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const [addItemModal, setAddItemModal] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);
  const [showFollowingModal, setShowFollowingModal] = useState(false);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [followingData, setFollowingData] = useState({});
  const [followersData, setFollowersData] = useState({});
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });
  const { handleSubmit, register, errors } = useForm();
  const UserName = location?.pathname.replace("/Profile/", "");

  const handleClose = () => {
    setShow(!show);
  };

  const handleEditeUserDataModal = () => {
    setShowEditModal(!showEditModal);
  };

  const pushEditAddressModal = async () => {
    setSpecificAddress(true);
  };

  const closeAddedAlert = () => {
    setItemAdded(false);
    setErrorAddItems(false);
    setReloadPage(!reloadPage);
  };

  const pushAddItemsModal = () => {
    setAddItemModal(true);
  };

  const pushAddAddressModal = () => {
    setAddAdressesModal(true);
  };

  const closeAddItemModal = () => {
    setAddItemModal(false);
  };

  const navigateToContact = () => {
    history.push({
      pathname: `/Contact/${personData?.username}`,
      state: {
        _id: personData?.id,
      },
    });
  };

  const editingSuccessfull = () => {
    setSuccessEdit(true);
    setReloadPage(!reloadPage);
  };

  const closeEditsuccessfull = () => {
    setSuccessEdit(false);
  };

  const handleFollwingModal = () => {
    setShowFollowingModal(false);
    setReloadfollow(!reloadfollow);
  };

  const handleFollwersModal = () => {
    setShowFollowersModal(false);
    setReloadfollow(!reloadfollow);
  };

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("accessToken");
      try {
        await axios({
          method: "get",
          url: `http://api.ateej.net/en/api/tweets/followers/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setIsLoading(false);
            const response = res.data;
            setFollowersData(response);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
        await axios({
          method: "get",
          url: `http://api.ateej.net/en/api/tweets/following/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setIsLoading(false);
            const response = res.data;
            setFollowingData(response);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [reloadfollow]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const token = localStorage.getItem("accessToken");
      const _userData = localStorage.getItem("userData");
      setAccessToken(token);
      // setPersonData(JSON.parse(_userData));
      try {
        const Items = JSON.parse(localStorage.getItem("itemsArray")) || [];
        setCheckoutItems(Items);
        await axios({
          method: "get",
          url: "http://api.ateej.net/en/api/auth_user/profile/",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setIsLoading(false);
            const response = res.data.context;
            response?.username != UserName && history.push("/NotFound");
            setPersonData(response);
            setValues({
              ...values,
              firstName: response?.first_name,
              lastName: response?.last_name,
              phoneNumber: response?.phone,
              email: response?.email,
            });
          })
          .catch((error) => {
            if (error?.response.status === 401) {
              history.push("/login");
            }
            setIsLoading(true);
            const errorToSend = error?.response?.statusText?.replace(
              / /g,
              "_"
            )(async () => {
              try {
                await axios({
                  method: "post",
                  url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
                })
                  .then((res) => {
                    const errorCatched = res.data;
                    setResponseError(errorCatched);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            })();
          });
        await axios({
          method: "get",
          url: "http://api.ateej.net/en/api/items/user/",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            console.log({ res });
            setIsLoading(false);
            const response = res.data.context;
            setItemsData(response);
          })
          .catch((error) => {
            console.log({ error });
            setIsLoading(true);
            const errorToSend = error?.response?.statusText?.replace(/ /g, "_");
            (async () => {
              try {
                await axios({
                  method: "post",
                  url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
                })
                  .then((res) => {
                    const errorCatched = res.data;
                    setResponseError(errorCatched);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            })();
          });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
      await axios({
        method: "get",
        url: "http://api.ateej.net/en/api/addresses/",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setIsLoading(false);
          const response = res.data.context;
          setAddressesData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          const errorToSend = error?.response?.statusText?.replace(/ /g, "_");
          console.log("errorToSend", errorToSend);
          (async () => {
            await axios({
              method: "post",
              url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
            })
              .then((res) => {
                const errorCatched = res.data;
                setResponseError(errorCatched);
              })
              .catch((error) => {
                console.log(error);
              });
          })();
        });
    })();
  }, [state.changeCartItems, reloadPage, refreshPage]);

  if (isLoading === true) {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <ReactLoading
          type="bubbles"
          height={150}
          width={150}
          color={Theme.colors.darkGrey}
        />
      </div>
    );
  }

  return (
    <div className="container-fluid h-100 mb-5">
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <h1>
            {t("Welcome")} {personData?.first_name} {personData?.last_name}
          </h1>
          <span
            onClick={() => handleClose()}
            style={{ cursor: "pointer" }}
            className="mx-3"
          >
            {t("Edit your profile")} <AiFillEdit size={20} />
          </span>
        </div>
        <div className="col-12">
          <Image
            className="rounded"
            style={{ height: 200, width: "100%", objectFit: "cover" }}
            src={Cover}
          />
        </div>
        <div
          style={{ top: -100 }}
          className="col-12 d-flex flex-column flex-md-row align-items-center align-items-md-end"
        >
          <div
            className="card ml-0 ml-md-3 d-flex justify-content-center align-items-center"
            style={{
              height: 200,
              width: 200,
              backgroundColor: Theme.colors.white,
            }}
          >
            {personData?.image != null ? (
              <Image
                className="rounded"
                height="200"
                width="200"
                style={{ objectFit: "cover" }}
                src={personData?.image}
              />
            ) : (
              <BsFillPersonFill
                className="rounded-circle p-1"
                style={{
                  backgroundColor: Theme.colors.darkGrey,
                  color: Theme.colors.white,
                }}
                size={56}
              />
            )}
            {personData.badge_confirmed == true && (
              <Image
                style={{ position: "absolute", right: 0, bottom: 0 }}
                height="35"
                width="40"
                src={BadgeConfirmed}
              />
            )}
            {personData.badge_identity_types == true &&
            personData.badge_confirmed == true ? (
              <Image
                style={{ position: "absolute", right: 0, bottom: 0 }}
                height="35"
                width="40"
                src={BadgeIdentityTypes}
              />
            ) : (
              personData.badge_identity_types == true && (
                <Image
                  style={{ position: "absolute", right: 0, bottom: 0 }}
                  height="35"
                  width="40"
                  src={FullConfirmed}
                />
              )
            )}
          </div>
          <div className="d-flex flex-column flex-md-row w-75">
            <div className="d-flex flex-column flex-md-row w-100">
              <div className="m-3">
                <p className="my-0">
                  {t("name")}: {personData?.first_name} {personData?.last_name}
                </p>
                <p className="my-0">
                  {t("username")}: @{personData?.username}
                </p>
              </div>
              <div className="border-left my-3 mx-0 mx-md-3 my-md-3">
                <p className="mx-1 my-0 p-0">
                  <span>
                    <AiOutlineMail className="mx-1" size={15} />
                  </span>
                  {t("email")}: {personData?.email}
                </p>
                <p className="mx-1 my-0 p-0">
                  <span>
                    <AiOutlinePhone className="mx-1" size={15} />
                  </span>
                  {t("phone")}: {personData?.phone}
                </p>
              </div>
              <div className="border-left my-3 mx-0 mx-md-3 my-md-3">
                {addressesData.length > 0 ? (
                  <p className="mx-1 my-0 p-0 d-flex justify-content-between align-items-center">
                    <span className="d-flex">
                      <GoLocation className="mx-1" size={15} />
                      <p
                        onClick={() => pushEditAddressModal()}
                        className="m-0"
                        style={{
                          width: "40%",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          fontSize: 12,
                          cursor: "pointer",
                          color: Theme.colors.blue,
                        }}
                      >{`${addressesData[0]?.area}, ${addressesData[0]?.street}`}</p>
                    </span>{" "}
                    <span
                      onClick={() => setAdressesModal(true)}
                      style={{
                        cursor: "pointer",
                        color: Theme.colors.blue,
                      }}
                      className="ml-3 my-auto"
                    >
                      <AiFillSetting size={15} />
                    </span>
                  </p>
                ) : (
                  <p
                    onClick={() => pushAddAddressModal()}
                    className="my-0 mx-1"
                    style={{ cursor: "pointer" }}
                  >
                    <span className="mx-1">
                      <AiOutlinePlusCircle size={15} />
                    </span>
                    {t("Add new address")}
                  </p>
                )}
                <p className="mx-1 my-0 p-0">
                  <span>
                    <AiOutlineStar className="mx-1" size={15} />
                  </span>
                  {t("rating")}:{" "}
                  <span>
                    <Rating
                      readonly={true}
                      placeholderRating={
                        personData?.rating == "None" ? 0 : personData?.rating
                      }
                      emptySymbol={
                        <AiFillStar size={15} color={Theme.colors.darkGrey} />
                      }
                      placeholderSymbol={
                        <AiFillStar size={15} color={Theme.colors.yellow} />
                      }
                      fullSymbol={
                        <AiFillStar size={15} color={Theme.colors.yellow} />
                      }
                    />
                  </span>
                </p>
              </div>
              <div className="border-left my-3 mx-0 mx-md-3 my-md-3">
                <p
                  onClick={() => setShowFollowingModal(true)}
                  style={{ cursor: "pointer" }}
                  className="mx-1 my-0 p-0"
                >
                  {t("following")}: {personData.following_count}
                </p>
                <p
                  onClick={() => setShowFollowersModal(true)}
                  style={{ cursor: "pointer" }}
                  className="mx-1 my-0 p-0"
                >
                  {t("followers")}: {personData.followers_count}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p
              style={{ overFlow: "auto", whiteSpace: "nowrap" }}
              className="mx-1 my-0 p-0"
            >
              <b>
                {t("total badge")}: {personData?.badge_points_total} $US
              </b>
            </p>
            <p
              onClick={() => navigateToContact()}
              style={{
                overFlow: "auto",
                whiteSpace: "nowrap",
                color: Theme.colors.blue,
                cursor: "pointer",
              }}
            >
              {t("Messages")}
              <span className="mx-2">
                <BsFillChatDotsFill size={20} />
              </span>
            </p>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between my-2">
          <h4 className="my-0">{t("Checkout Items")}</h4>
          <Button
            onClick={() => pushAddItemsModal()}
            variant="primary rounded-0"
          >
            {t("Add Item")}
          </Button>
        </div>
        <div className="d-flex flex-wrap justify-content-center p-0">
          {itemsData.length > 0 ? (
            itemsData?.map((item, index) => {
              return <Card index={index} item={item} />;
            })
          ) : (
            <div className="m-auto">
              <h6>{t("there is no item added")}</h6>
            </div>
          )}
        </div>
      </div>
      <Modal show={addAdressesModal} onHide={() => setAddAdressesModal(false)}>
        <AddAddressModal
          setAddAdressesModal={() => setAddAdressesModal(false)}
          setItemAdded={() => setItemAdded(true)}
          setErrorAddItems={() => setErrorAddItems(true)}
        />
      </Modal>
      <Modal show={adressesModal} onHide={() => setAdressesModal(false)}>
        <AdressesModal
          data={addressesData}
          setAdressesModal={() => setAdressesModal(false)}
          setRefreshPage={() => setRefreshPage(!refreshPage)}
          setItemAdded={() => setItemAdded(true)}
          setErrorAddItems={() => setErrorAddItems(true)}
        />
      </Modal>
      <Modal show={specificAddress} onHide={() => setSpecificAddress(false)}>
        <EditAdressModal
          setSpecificAddress={() => setSpecificAddress(false)}
          addressesData={addressesData}
        />
      </Modal>
      <Modal show={show} onHide={() => handleClose()}>
        <EditUserDataModal
          setShow={() => setShow(!show)}
          data={values}
          reloadPage={() => editingSuccessfull()}
        />
      </Modal>
      <Modal show={showEditModal} onHide={() => handleEditeUserDataModal()}>
        <EditUserDataModal
          setShow={() => handleEditeUserDataModal()}
          data={values}
          reloadPage={() => editingSuccessfull()}
        />
      </Modal>
      <Modal show={showFollowingModal} onHide={() => handleFollwingModal()}>
        <FollowingModal
          data={followingData}
          CloseModal={() => handleFollwingModal()}
        />
      </Modal>
      <Modal show={showFollowersModal} onHide={() => handleFollwersModal()}>
        <FollowersModal
          data={followersData}
          CloseModal={() => handleFollwersModal()}
        />
      </Modal>
      {itemAdded && (
        <SweetAlert
          type="success"
          title={t("address added successfully")}
          onConfirm={() => closeAddedAlert()}
          focusCancelBtn={true}
        />
      )}
      {errorAddItems && (
        <SweetAlert
          type="error"
          title={t("error with adding address")}
          onConfirm={() => closeAddedAlert()}
          focusCancelBtn={true}
        />
      )}
      {addItemModal && (
        <Modal show={addItemModal} onHide={() => closeAddItemModal()}>
          <AddItemForm />
        </Modal>
      )}
      {successEdit && (
        <SweetAlert
          type="success"
          title={t("data changed successfully")}
          onConfirm={() => closeEditsuccessfull()}
          focusCancelBtn={true}
        />
      )}
    </div>
  );
};

export default Profile;
