import React, { useState, useEffect,useContext } from "react";
import { Image,Dropdown,Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Theme from "../../../../styles/theme";
import {Context as mainContext} from "../../../../Context/mainContext";

const Card = ({ data,maxQuantity }) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const {changeReciverId,changeUserName,changeItemId} = useContext(mainContext);
    const [newImages, setNewImages] = useState([]);
    useEffect(() => {
        setNewImages(data?.image)
    }, []);
    const rootTitle = data?.root?.title?.replace(/ /g,"_")
    const categoryTitle = data?.category?.title?.replace(/ /g,"_")
    const navigateToDetails =()=>{
        history.push({
            pathname: `/${rootTitle}/${categoryTitle}/${data?.id}`,
            state: {
                _id: data?.id,
            }
        })
      changeItemId(data?.id)
    }

    const navigateToSellerProfile =()=>{
        history.push({
            pathname: `/User/${data?.seller?.firstname} ${data?.seller?.lastname}`,
            state: {
                _id: data?.seller?.id,
            }
        })
    }
    const navigateToContact =()=>{
        console.log("pressed");
        changeReciverId(data?.seller?.id)
        changeUserName(data?.seller?.firstname+" "+data?.seller?.lastname)
        history.push({
            pathname: `/Contact/${data?.seller?.firstname} ${data?.seller?.lastname}`,
            state: {
                _id: data?.seller?.id
            }
        })
    }
    const finalQuantity=maxQuantity.find(item=>item.id===data?.id)
    return (
        <div className="d-flex mb-5">
            <Image onClick={()=>navigateToDetails()} style={{ objectFit: "contain",cursor:"pointer" }} width="150" height="100" src={newImages[0]} />
            <div className="mx-3">
                <h4>{data?.title}</h4>
                <p style={{ color: Theme.colors.darkGrey }}>{data?.item_serial}</p>
                <p style={{ color: Theme.colors.black }}>US ${data?.price_total}</p>
                <p style={{ color: Theme.colors.black }}>{t('Selected quantity is')}: {finalQuantity?.availableQuantity}</p>
                <p style={{ color: Theme.colors.black,fontSize:12 }}>{t('Seller Name')}: <span onClick={()=>navigateToSellerProfile()} style={{color:"#007bff",cursor:"pointer"}}><b>{data?.seller?.firstname}{" "}{data?.seller?.lastname}</b></span></p>
                <p style={{ color: Theme.colors.black,fontSize:12 }}>{t('Seller Country')}: {data?.country?.name}</p>
                <div className="d-flex justify-content-start align-items-center">
                    <button onClick={() => navigateToContact()} style={{fontSize:15,backgroundColor:"#007bff"}} class="button py-2"><span>{t('Contact with seller')}</span></button>
                    <Button style={{fontSize:15,color:"#007bff"}} variant="light mx-2">{t('Add for later shipping')}</Button>
                </div>
            </div>
        </div>
    )
}
export default Card;