import React from "react";
import { useTranslation } from 'react-i18next';

const NotFound =()=>{
    const { t, i18n } = useTranslation();
    return(
        <div className="mb-5 p-5">            
            <h1 className="text-center">{t('error 404 this page is not found')}</h1>
        </div>
    )
}

export default NotFound;