import React, { useContext, useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoIosNotificationsOutline } from "react-icons/io";
import { BiCart } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { MdLanguage } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";

import { Context as AuthContext } from "../../Context/AuthContext.js";
import Menu from "./component/menu";
import "./DropDown.scss";

const DropDown = ({
  title,
  items = [],
  notification,
  cart,
  Profile,
  languages,
  personData,
}) => {
  const { t, i18n } = useTranslation();
  const { state, changeToken } = useContext(AuthContext);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [dropDownTitle, setDropDownTitle] = useState(title);
  const [accessToken, setAccessToken] = useState(null);
  const showDropdown = (e) => {
    setShow(!show);
  };

  const logout = () => {
    changeToken();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("itemsArray");
    localStorage.removeItem("availableQuantity");
    localStorage.removeItem("userData");
    history.push("/");
    window.location.reload();
  };
  const handilClick = (lang) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    window.location.reload();
  };
  const navigateToProfile = () => {
    history.push(`/Profile/${personData?.username}`);
  };

  useEffect(() => {
    (async () => {
      const token = await localStorage.getItem("accessToken");
      setAccessToken(token);
    })();
  }, [state?.token]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="light"
        id="dropdown-basic"
        style={{ fontSize: 12 }}
      >
        {title && (
          <button className="dropbtn">
            {dropDownTitle}
            <IoMdArrowDropdown className="mx-1" size={15} />
          </button>
        )}
        {notification === true && <IoIosNotificationsOutline size={15} />}
        {cart === true && <BiCart size={15} />}
        {Profile && (
          <>
            <BsFillPersonFill size={15} />
            <IoMdArrowDropdown className="mx-1" size={15} />
          </>
        )}
        {languages == true && (
          <>
            <MdLanguage size={15} />
            <IoMdArrowDropdown className="mx-1" size={15} />
          </>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Profile ? (
          <div>
            {accessToken === null ? (
              <p
                className="mx-3"
                onClick={() => history.push("/login")}
                style={{ cursor: "pointer", fontSize: 12 }}
              >
                login
              </p>
            ) : (
              <>
                <p
                  className="mx-3"
                  onClick={() => navigateToProfile()}
                  style={{ cursor: "pointer", fontSize: 12 }}
                >
                  profile
                </p>
                <p
                  className="mx-3"
                  onClick={() => history.push("/")}
                  style={{ cursor: "pointer", fontSize: 12 }}
                >
                  account setting
                </p>
                <p
                  className="mx-3"
                  onClick={() => logout()}
                  style={{ cursor: "pointer", fontSize: 12 }}
                >
                  logout
                </p>
              </>
            )}
          </div>
        ) : languages == true ? (
          <div>
            <p
              className="mx-3"
              onClick={() => handilClick(`en`)}
              style={{ cursor: "pointer", fontSize: 12 }}
            >
              English
            </p>
            <p
              className="mx-3"
              onClick={() => handilClick(`ar`)}
              style={{ cursor: "pointer", fontSize: 12 }}
            >
              العربية
            </p>
          </div>
        ) : items?.length ? (
          items?.map((item, index) => {
            return (
              <Menu
                key={index}
                item={item}
                setTitle={() => setDropDownTitle(item.title)}
              />
            );
          })
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default DropDown;
