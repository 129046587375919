const Theme = {
  colors: {
    primary: "#2CB9B0",
    secondary: "#0C0D34",
    danger: "#FF0058",
    text: "rgba(12,13,52,0.7)",
    white: "#fff",
    black: "#000",
    grey: "#F4F0EF",
    lightGrey: "#FAFAFA",
    darkGrey: "#808080",
    primaryLight: "#E7F9F7",
    orange: "#FE5E33",
    yellow: "#FFC641",
    pink: "#FF87A2",
    violet: "#442CB9",
    lightBlue: "#BFEAF5",
    blue: "#2771F3",
    darkBlue: "#2C0FAF",
    extraDarkBlue: "#0E326C",
    semiBlue:"#6F9FF7",
    lightGreen:"#8DE0E0",
    darkGreen:"#469FAA",
    green:"#00FF00",
    beige:"#F5F5DC",
    paymentBorderColor:"#DADADA"
  },
  spacing: {
    s: 8,
    m: 16,
    l: 24,
    xl: 40,
  },
  borderRadii: {
    s: 4,
    m: 10,
    l: 25,
    xl: 75,
  },
  textVariants: {
    hero: {
      fontSize: 80,
      lineHeight: 80,
      fontFamily: "din",
      color: "white",
      alignSelf: "center",
    },
    title1: {
      fontSize: 28,
      fontFamily: "din",
      color: "secondary",
    },
    title2: {
      fontSize: 24,
      lineHeight: 30,
      fontFamily: "din",
      color: "secondary",
    },
    title3: {
      fontSize: 16,
      lineHeight: 30,
      fontFamily: "din",
      color: "secondary",
    },
    body: {
      fontSize: 16,
      lineHeight: 24,
      fontFamily: "din",
      color: "text",
    },
    button: {
      fontSize: 15,
      fontFamily: "din",
      color: "text",
      textAlign: "center",
    },
    header: {
      fontSize: 14,
      lineHeight: 24,
      fontFamily: "din",
      color: "secondary",
    },
  }
}
export default Theme;
