const Product = {
    Parts: [
        {
            id: 1,
            title: "PC Laptops & Netbooks",
            items: [
                {
                    id: 1,
                    title: "laptop",
                    description: "Lenovo ZA6F0031US IdeaPad Duet Chromebook 10.1 FHD Touchscreen MediaTek P60T",
                    fixedprice: 250,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
                },
                {
                    id: 2,
                    title: "laptop",
                    description: "Lenovo ZA6F0031US IdeaPad Duet Chromebook 10.1 FHD Touchscreen MediaTek P60T",
                    fixedprice: 150,
                    haveDiscount: true,
                    discount: 50,
                    img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
                },
                {
                    id: 3,
                    title: "laptop",
                    description: "Lenovo ZA6F0031US IdeaPad Duet Chromebook 10.1 FHD Touchscreen MediaTek P60T",
                    fixedprice: 750,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
                },
                {
                    id: 4,
                    title: "laptop",
                    description: "Lenovo ZA6F0031US IdeaPad Duet Chromebook 10.1 FHD Touchscreen MediaTek P60T",
                    fixedprice: 650,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
                },
                {
                    id: 5,
                    title: "laptop",
                    description: "Lenovo ZA6F0031US IdeaPad Duet Chromebook 10.1 FHD Touchscreen MediaTek P60T",
                    fixedprice: 750,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
                },
                {
                    id: 6,
                    title: "laptop",
                    description: "Lenovo ZA6F0031US IdeaPad Duet Chromebook 10.1 FHD Touchscreen MediaTek P60T",
                    fixedprice: 650,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
                },
                {
                    id: 7,
                    title: "laptop",
                    description: "Lenovo ZA6F0031US IdeaPad Duet Chromebook 10.1 FHD Touchscreen MediaTek P60T",
                    fixedprice: 750,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
                },
                {
                    id: 8,
                    title: "laptop",
                    description: "Lenovo ZA6F0031US IdeaPad Duet Chromebook 10.1 FHD Touchscreen MediaTek P60T",
                    fixedprice: 650,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
                },
                {
                    id: 9,
                    title: "laptop",
                    description: "Lenovo ZA6F0031US IdeaPad Duet Chromebook 10.1 FHD Touchscreen MediaTek P60T",
                    fixedprice: 750,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
                },
                {
                    id: 10,
                    title: "laptop",
                    description: "Lenovo ZA6F0031US IdeaPad Duet Chromebook 10.1 FHD Touchscreen MediaTek P60T",
                    fixedprice: 650,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
                }
            ]
        },
        {
            id: 2,
            title: "Watches",
            items: [
                {
                    id: 1,
                    title: "Rolex",
                    description: "Rolex Explorer Stainless Steel Black Dial Mens 39mm Watch / Box 214270",
                    fixedprice: 250,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/5loAAOSwDUpfIvbC/s-l1600.jpg"
                },
                {
                    id: 2,
                    title: "Omega",
                    description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                    fixedprice: 150,
                    haveDiscount: true,
                    discount: 50,
                    img: "https://i.ebayimg.com/images/g/5loAAOSwDUpfIvbC/s-l1600.jpg"
                },
                {
                    id: 3,
                    title: "Breiling",
                    description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                    fixedprice: 750,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/5loAAOSwDUpfIvbC/s-l1600.jpg"
                },
                {
                    id: 4,
                    title: "Cartier",
                    description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                    fixedprice: 650,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/5loAAOSwDUpfIvbC/s-l1600.jpg"
                },
                {
                    id: 4,
                    title: "Tag Hever",
                    description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                    fixedprice: 650,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/5loAAOSwDUpfIvbC/s-l1600.jpg"
                }
            ]
        },
        {
            id: 3,
            title: "Mobiles",
            items: [
                {
                    id: 1,
                    title: "Iphone 11",
                    description: "Rolex Explorer Stainless Steel Black Dial Mens 39mm Watch / Box 214270",
                    fixedprice: 250,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/5loAAOSwDUpfIvbC/s-l1600.jpg"
                },
                {
                    id: 2,
                    title: "Iphone X",
                    description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                    fixedprice: 150,
                    haveDiscount: true,
                    discount: 50,
                    img: "https://i.ebayimg.com/images/g/5loAAOSwDUpfIvbC/s-l1600.jpg"
                },
                {
                    id: 3,
                    title: "Samsung",
                    description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                    fixedprice: 750,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/5loAAOSwDUpfIvbC/s-l1600.jpg"
                },
                {
                    id: 4,
                    title: "Redmy",
                    description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                    fixedprice: 650,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/5loAAOSwDUpfIvbC/s-l1600.jpg"
                },
                {
                    id: 4,
                    title: "Iphone 12",
                    description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                    fixedprice: 650,
                    haveDiscount: false,
                    discount: 0,
                    img: "https://i.ebayimg.com/images/g/5loAAOSwDUpfIvbC/s-l1600.jpg"
                }
            ]
        },
    ],
    discountProduct: {
        id: 1,
        title: "The perfect gift. Priced right.",
        description: "Certified Refurbished brands they love.",
        items: [
            {
                id: 1,
                title: "laptop",
                description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                fixedprice: 500,
                discount: 200,
                img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
            },
            {
                id: 2,
                title: "laptop",
                description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                fixedprice: 500,
                discount: 200,
                img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
            },
            {
                id: 3,
                title: "laptop",
                description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                fixedprice: 500,
                discount: 200,
                img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
            },
            {
                id: 4,
                title: "laptop",
                description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                fixedprice: 500,
                discount: 200,
                img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
            },
            {
                id: 5,
                title: "laptop",
                description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                fixedprice: 500,
                discount: 200,
                img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
            },
            {
                id: 6,
                title: "laptop",
                description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                fixedprice: 500,
                discount: 200,
                img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
            },
            {
                id: 7,
                title: "laptop",
                description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                fixedprice: 500,
                discount: 200,
                img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
            },
            {
                id: 8,
                title: "laptop",
                description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                fixedprice: 500,
                discount: 200,
                img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
            },
            {
                id: 9,
                title: "laptop",
                description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                fixedprice: 500,
                discount: 200,
                img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
            },
            {
                id: 10,
                title: "laptop",
                description: "Omega Datejust 41 White Gold Steel Blue Roman Dial Automatic Mens Watch 126334",
                fixedprice: 500,
                discount: 200,
                img: "https://i.ebayimg.com/images/g/kWgAAOSwf2dfGgeS/s-l1600.jpg"
            }
        ]
    }
}

export default Product;