import React,{useContext} from "react";
import { Image } from "react-bootstrap";

import Theme from "../../styles/theme";
import {Context as mainContext} from "../../Context/mainContext";
import "./Card.scss";
import {useHistory} from "react-router-dom";

const Card = ({  haveDiscount, discount, listIndex,category_id,index , data }) => {
    const lastPrice = data?.price_total - discount;
    const avarage = (lastPrice / data?.price_total) * 100;
    const history = useHistory();
    const {changeItemId} = useContext(mainContext);
    const rootTitle = data?.root?.title?.replace(/ /g,"_")
    const categoryTitle = data?.category?.title?.replace(/ /g,"_")
        const navigateToDetails =()=>{
            localStorage.setItem("CategoryId", category_id);
            history.push({
                pathname: `/${rootTitle}/${categoryTitle}/${data?.id}`,
                state: {
                    _id: data?.id,
                }
            })
            changeItemId(data?.id)
        }
    return (
        <>
            {
                listIndex === 2 ? <div onClick={()=>navigateToDetails()} className="collection-item rounded-circle py-4 my-4" style={{ backgroundColor: index % 2 !== 0?Theme.colors.grey:Theme.colors.white, color: "inherit",cursor: "pointer" }}>
                    <Image
                        className="image rounded-circle"
                        src={data?.thumbnail}
                        style={{ objectFit: "fill" }}
                        width="120"
                        height="120"
                    />
                    <div className="collection-footer m-3">
                        <h5 style={{ width:"80%",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden" }} className="mx-auto name">{data?.title}</h5>
                    </div>
                </div> :
                    <div onClick={()=>navigateToDetails()}
                        style={{ color: "inherit",cursor: "pointer" }} className="p-0 mx-2">
                        <div className="collection-item rounded" style={{ backgroundColor: Theme.colors.white, width: 200 }}>
                            <Image
                                className="image m-2"
                                src={data?.thumbnail}
                                style={{ objectFit: "fill" }}
                                width="180"
                                height="180"
                            />
                            <div onClick={()=>navigateToDetails()} className="collection-footer ml-3 mb-3">
                                {
                                    haveDiscount === true ? <div onClick={()=>navigateToDetails()} style={{ color: "inherit" }} className="d-flex flex-column">
                                        <span style={{ fontSize: 12 }} className="price">US ${data?.price_total}</span>
                                    </div>
                                        :
                                        <span style={{ fontSize: 12 }} className="price">US ${data?.price_total}</span>
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Card;