import React,{useEffect,useState,useContext} from "react";
import {useLocation} from "react-router-dom";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';

import Card from "./components/Card";
import BrandList from "./components/BrandList";
import Theme from "../../styles/theme";
import SubCategoryList from "../../pages/Category/component/SubCategoryList";
import {Context as mainContext} from "../../Context/mainContext";
const BrandsDetails=()=>{
    const {state} = useContext(mainContext)
    const location = useLocation();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [brandsData,setBrandData] = useState([]);
    const [brands,setBrands] = useState([]);
    const [responseError, setResponseError] = useState({});
    const [itemCategoryLevelThree,setItemCategoryLeveThree]= useState([]);
    const data = location.state;
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                await axios({
                    method: 'get',
                    url: `http://api.ateej.net/en/api/items/brand/${state.brandId}`
                }).then(res => {
                    setIsLoading(false);
                    const brandResponse = res.data;
                    setBrandData(brandResponse?.context);
                }).catch(error => {
                    setIsLoading(true);
                    const errorToSend = error?.response?.statusText?.replace(/ /g, "_")
                        (async () => {
                            try {
                                await axios({
                                    method: 'post',
                                    url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`
                                }).then(res => {
                                    const errorCatched = res.data;
                                    setResponseError(errorCatched);
                                }).catch(error => {
                                    console.log(error);
                                })
                                setIsLoading(false);
                            } catch (error) {
                                setIsLoading(false);
                                console.log(error);
                            }
                        })()
                })
                await axios({
                    method: 'get',
                    url: `http://api.ateej.net/en/api/items/category/menu/2`,
                }).then(res => {
                    const response = res.data.context;
                    setItemCategoryLeveThree(response);
                }).catch(error => {
                    setIsLoading(true);
                    (async () => {
                   setIsLoading(true);
                    const errorToSend = error?.response?.statusText.replace(/ /g, "_")
                    error.response.status == 404 && history.push("/NotFound")
                        try {
                            await axios({
                                method:'post',
                                url:`http://api.ateej.net/en/api/logs/web/${errorToSend}`
                            }).then(res => {
                                const errorCatched = res.data;
                                setResponseError(errorCatched);
                            }).catch(error=>{
                                console.log(error);
                            })   
                            setIsLoading(false);
                        } catch (error) {
                            setIsLoading(false);
                            console.log(error);
                        }
                    })();
                })
                await axios({
                    method: 'get',
                    url: `http://api.ateej.net/en/api/brands/`
                }).then(res => {
                    setIsLoading(false);
                    const brandResponse = res.data.context;
                    setBrands(brandResponse);
                }).catch(error => {
                    setIsLoading(true);
                    const errorToSend = error?.response?.statusText.replace(/ /g, "_")
                    error.response.status == 404 && history.push("/NotFound")
                        (async () => {
                            try {
                                await axios({
                                    method: 'get',
                                    url: `http://api.ateej.net/en/api/log/web/${errorToSend}`
                                }).then(res => {
                                    const errorCatched = res.data;
                                    setResponseError(errorCatched);
                                }).catch(error => {
                                    console.log(error);
                                })
                                setIsLoading(false);
                            } catch (error) {
                                setIsLoading(false);
                                console.log(error);
                            }
                        })()
                })
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [state.brandId])
    return(
        <>
        {isLoading===true?<div style={{height:"100vh"}} className="d-flex justify-content-center align-items-center">
            <ReactLoading type="bubbles" height={150} width={150} color={Theme.colors.darkGrey}/></div>:
        <div className="container-fluid">
            <div className="row">
                <h2 style={{backgroundColor:Theme.colors.lightGreen}} className="col-12 col-md-9 py-2 m-0">{data?._name}</h2>
                <div style={{ backgroundColor: Theme.colors.darkGreen}} className="col-0 col-md-2"/>
                <div style={{ backgroundColor: Theme.colors.semiBlue }} className="col-0 col-md-1"/>
                <div className="col-12 col-md-2 border rounded m-0 m-md-4 mx-auto p-2">
                    <h6 className="m-0">{t('shop by category')}</h6>
                    <div className="my-3">
                        {
                            itemCategoryLevelThree?.map((item,index)=>{
                                return(
                                    <div key={item.id}>
                                        <p className="p-2 custome_card" style={{cursor: "pointer",backgroundColor:Theme.colors.grey}}><b>{item.title}</b></p>
                                        {
                                            item?.childs?.map((item,index)=>{
                                                return(
                                                    <SubCategoryList data={item} rootTitle={item?.title} key={index} />
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col-12 col-md-9 m-0 m-md-4 mx-auto">
                    <div className="border rounded mx-auto">
                    <div className="d-flex justify-content-between">
                        <h2 className="m-3">{t('Shop by Brand')}</h2>
                    </div>
                    <div style={{backgroundColor: Theme.colors.lightGrey}} className="d-flex flex-wrap justify-content-start">
                        {
                            brands?.map(item=>{
                                return(
                                    <BrandList key={item?.id} data={item}/>
                                )
                            })
                        }
                    </div>
                    <div className="d-flex justify-content-between">
                        <h2 className="m-3">{t('Limited time Deals')}</h2>
                    </div>
                    <div style={{ backgroundColor: Theme.colors.lightGrey }} className="d-flex flex-wrap justify-content-start">
                        {
                            brandsData?.map(item=>{
                                return(
                                    <Card key={item.id} data={item}/>
                                )
                            })
                        }
                    </div>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}

export default BrandsDetails;