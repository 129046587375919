import React,{useContext} from "react";

import Theme from "../../../../styles/theme";
import { Context as mainContext } from "../../../../Context/mainContext";

const SelectSorting =({data})=>{
    const { state,addSortingToSearch } = useContext(mainContext);
    const searchWithSorting=()=>{
        addSortingToSearch(data.id)
    }
    return(
        <div onClick={() => searchWithSorting()} style={{ backgroundColor: Theme.colors.grey, borderColor: data.id == state?.sortingSearch ? Theme.colors.darkGrey : Theme.colors.grey ,borderWidth:2,borderStyle:"solid", cursor: "pointer" }} className="px-3 py-2 mx-3 my-2 rounded-pill">
            <h6 className="m-0" style={{overFlow: "auto",whiteSpace: "nowrap"}}>{data.title}</h6>
        </div>
    )
}

export default SelectSorting;