import React, { useState, useEffect, useContext } from "react";
import ReactLoading from "react-loading";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { Button, Form, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Rating from "react-rating";
import { AiFillStar } from "react-icons/ai";
import { IoIosArrowRoundDown } from "react-icons/io";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import SweetAlert from "react-bootstrap-sweetalert";

import AteejLogo from "../../assets/images/AteejLogo.png";
import Theme from "../../styles/theme";
import { Context as mainContext } from "../../Context/mainContext";
import { useApi } from "../../hooks/useFetch";
const RatingAndReviews = () => {
  const {
    state,
    changeFirstQuestionAnswer,
    changeSecondQuestionAnswer,
    changeThirdQuestionAnswer,
  } = useContext(mainContext);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { handleSubmit, register, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [rateValue, setRateValue] = useState(0);
  const [data, setData] = useState({});

  const [questionIndex, setQuestionIndex] = useState(0);
  const [reivewId, setReivewId] = useState(0);
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewDescription, setReviewDescription] = useState("");
  const [showImagesError, setShowImagesError] = useState(false);
  const [showQuestionsError, setShowQuestionsError] = useState(false);
  const [addedReviewAlert, setAddedReviewAlert] = useState(false);
  const [errorAddedReviewAlert, setErrorAddedReviewAlert] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [reviewAdded, setReviewAdded] = useState(false);
  const itemId = location?.pathname?.split("/")[3];
  const token = localStorage.getItem("accessToken");

  const [requestReviewsData, reviewsData, loadingReviews] = useApi();
  const [requestUserReview, userReviewData, loadingUserReview] = useApi();
  const [questions, setQuestions] = useState(
    Object.values(userReviewData?.questions || {}) || []
  );
  const [
    postReview,
    postReviewResponse,
    loadingPostReview,
    responseStatus,
  ] = useApi();
  const [
    putReview,
    putReviewResponse,
    loadingPutReview,
    putReviewStatus,
  ] = useApi();

  useEffect(() => {
    requestReviewsData("get", `/en/api/reviews/${itemId}`);
    requestUserReview("get", `/en/api/reviews/user/${itemId}`);
  }, [refreshPage]);

  useEffect(() => {
    setQuestions(Object.values(userReviewData?.questions || {}));
  }, [userReviewData?.questions]);

  const MAX_LENGTH = 5;
  var bodyFormData = new FormData();
  var newBodyFormData = new FormData();

  const toggleReviewAlert = (value) => {
    if (value === "success") {
      setAddedReviewAlert(true);
      setErrorAddedReviewAlert(false);
      setRefreshPage(!refreshPage);
    } else {
      setAddedReviewAlert(false);
      setErrorAddedReviewAlert(true);
    }
  };
  const onSubmit = async (values) => {
    values?.images?.length > MAX_LENGTH
      ? setShowImagesError(true)
      : setShowImagesError(false);
    state?.firstQuestionAnswer === null ||
    state?.secondQuestionAnswer === null ||
    state?.thirdQuestionAnswer === null
      ? setShowQuestionsError(true)
      : setShowQuestionsError(false);
    newBodyFormData.append("title", values?.title);
    newBodyFormData.append("review", values?.description);
    for (let index = 0; index < values?.images?.length; index++) {
      newBodyFormData.append("images", values?.images[index]);
    }
    postReview(
      "post",
      `/en/api/reviews/new/${itemId}/${
        state?.firstQuestionAnswer === null ? 1 : state?.firstQuestionAnswer
      }/${
        state?.secondQuestionAnswer === null ? 1 : state?.secondQuestionAnswer
      }/${
        state?.thirdQuestionAnswer === null ? 1 : state?.thirdQuestionAnswer
      }/${rateValue}`,
      {},
      newBodyFormData
    );
  };

  const onSubmitChanges = async (values) => {
    values?.images?.length > MAX_LENGTH
      ? setShowImagesError(true)
      : setShowImagesError(false);
    state?.firstQuestionAnswer === null ||
    state?.secondQuestionAnswer === null ||
    state?.thirdQuestionAnswer === null
      ? setShowQuestionsError(true)
      : setShowQuestionsError(false);
    bodyFormData.append("title", values?.title);
    bodyFormData.append("review", values?.description);
    for (let index = 0; index < values?.images?.length; index++) {
      bodyFormData.append("images", values?.images[index]);
    }
    putReview(
      "put",
      `/en/api/reviews/edit/${userReviewData?.id}/${
        state?.firstQuestionAnswer === null ? 1 : state?.firstQuestionAnswer
      }/${
        state?.secondQuestionAnswer === null ? 1 : state?.secondQuestionAnswer
      }/${
        state?.thirdQuestionAnswer === null ? 1 : state?.thirdQuestionAnswer
      }/${rateValue}`,
      {},
      bodyFormData
    );
  };

  const onSelect = (e) => {
    if (e.files.length > 5) {
      alert(`Please select max ${MAX_LENGTH} files.`);
      e.preventDefault();
    }
  };

  const uploadMultipleFiles = (e) => {
    if (Array.from(e.target.files).length > MAX_LENGTH) {
      e.preventDefault();
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      setShowImagesError(true);
      return;
    }
  };

  const rateChoises = [
    {
      id: 5,
      title: t("yes"),
    },
    {
      id: 1,
      title: t("no"),
    },
  ];

  const toggleChangeQuestion = (type) => {
    type === "next"
      ? questionIndex < questions.length - 1
        ? setQuestionIndex(questionIndex + 1)
        : setQuestionIndex(questionIndex)
      : questionIndex > 0
      ? setQuestionIndex(questionIndex - 1)
      : setQuestionIndex(questionIndex);
  };

  const size = 20;

  useEffect(() => {
    if (responseStatus === null) {
      setAddedReviewAlert(false);
      setErrorAddedReviewAlert(false);
      setReviewAdded(false);
    } else if (responseStatus === 201) {
      setAddedReviewAlert(true);
      setErrorAddedReviewAlert(false);
      setReviewAdded(true);
    } else {
      setAddedReviewAlert(false);
      setReviewAdded(false);
      setErrorAddedReviewAlert(true);
    }
  }, [responseStatus]);

  useEffect(() => {
    if (putReviewStatus === null) {
      setAddedReviewAlert(false);
      setErrorAddedReviewAlert(false);
      setReviewAdded(false);
    } else if (putReviewStatus === 205) {
      setAddedReviewAlert(true);
      setErrorAddedReviewAlert(false);
      setReviewAdded(true);
    } else {
      setAddedReviewAlert(false);
      setReviewAdded(false);
      setErrorAddedReviewAlert(true);
    }
  }, [putReviewStatus]);

  useEffect(() => {
    if (userReviewData?.rating_score !== undefined) {
      setRateValue(userReviewData?.rating_score);
      setReviewTitle(userReviewData?.title);
      setReviewDescription(userReviewData?.review);
    }
  }, [userReviewData?.rating_score]);

  if (
    loadingReviews ||
    loadingUserReview
    // loadingPostReview ||
    // loadingPutReview
  ) {
    return (
      <div>
        <ReactLoading
          type="bubbles"
          height={150}
          width={150}
          color={Theme.colors.darkGrey}
        />
      </div>
    );
  }
  
  return userReviewData?.new_review !== "True" ? (
    <div className="container-fluid mb-5">
      <div className="row">
        <div className="col-12 d-flex justify-content-between px-3 py-2 border-bottom mb-5">
          <h2>{t("Rating and Reviews")}</h2>
          {/* <Button className="my-0 py-0 rounded-0">{t('write a review')}</Button> */}
        </div>
        <div className="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center">
          <p
            className="my-0"
            style={{ fontSize: 50, color: Theme.colors.darkGrey }}
          >
            {rateValue}
          </p>
          <Rating
            initialRating={rateValue}
            onChange={(value) => setRateValue(value)}
            emptySymbol={<AiFillStar size={32} color={Theme.colors.darkGrey} />}
            placeholderSymbol={
              <AiFillStar size={32} color={Theme.colors.yellow} />
            }
            fullSymbol={<AiFillStar size={32} color={Theme.colors.yellow} />}
          />
        </div>
        <div className="col-12 col-md-6 my-3 my-md-0">
          <div className="d-flex justify-content-between align-items-center">
            <IoIosArrowBack
              onClick={() => toggleChangeQuestion("back")}
              style={{ cursor: "pointer" }}
              size={30}
              color={
                questionIndex === 0
                  ? Theme.colors.darkGrey + 50
                  : Theme.colors.darkGrey
              }
            />
            <p className="my-0" style={{ color: Theme.colors.black }}>
              {questions[questionIndex]}
            </p>
            <IoIosArrowForward
              onClick={() => toggleChangeQuestion("next")}
              style={{ cursor: "pointer" }}
              size={30}
              color={
                questionIndex + 1 === questions?.length
                  ? Theme.colors.darkGrey + 50
                  : Theme.colors.darkGrey
              }
            />
          </div>
          <div className="d-flex justify-content-center w-100 mt-2">
            {rateChoises?.map((item, index) => {
              const toggleAnswer = () => {
                questionIndex === 0
                  ? changeFirstQuestionAnswer(item?.id)
                  : questionIndex === 1
                  ? changeSecondQuestionAnswer(item?.id)
                  : changeThirdQuestionAnswer(item?.id);
              };
              return (
                <p
                  key={item?.id}
                  onClick={() => toggleAnswer()}
                  className="rounded-pill my-1 mx-3 py-1 px-4"
                  style={{
                    backgroundColor: Theme.colors.grey,
                    borderColor:
                      questionIndex === 0
                        ? state?.firstQuestionAnswer === item?.id
                          ? Theme.colors.darkGrey
                          : Theme.colors.grey
                        : questionIndex === 1
                        ? state?.secondQuestionAnswer === item?.id
                          ? Theme.colors.darkGrey
                          : Theme.colors.grey
                        : state?.thirdQuestionAnswer === item?.id
                        ? Theme.colors.darkGrey
                        : Theme.colors.grey,
                    bordrWidth: 2,
                    borderStyle: "solid",
                    cursor: "pointer",
                  }}
                >
                  {item.title}
                </p>
              );
            })}
          </div>
          <form
            name="form"
            className="d-flex flex-column justify-content-center align-items-center"
            onSubmit={handleSubmit(onSubmitChanges)}
          >
            <Form.Control
              name="title"
              ref={register({
                required: "Required",
              })}
              value={reviewTitle}
              maxlength="140"
              onChange={(e) => setReviewTitle(e?.target?.value)}
              className="my-2"
              type="text"
              placeholder={t("i recommend it")}
            />
            <Form.Control
              name="description"
              ref={register({
                required: "Required",
              })}
              value={reviewDescription}
              onChange={(e) => setReviewDescription(e?.target?.value)}
              className="my-2"
              maxlength="5000"
              as="textarea"
              rows={3}
              placeholder={t("Really great")}
            />
            <div className="d-flex flex-column w-100 align-items-start">
              <Form.File
                name="images"
                ref={register({
                  required: "Required",
                })}
                multiple
                maxlength="4"
                onChange={(e) => uploadMultipleFiles(e)}
                className="my-2"
                id="exampleFormControlFile1"
                label={t("Add photo (up to 5 photos)")}
              />
            </div>
            <div className="d-flex justify-content-end w-100 mt-0 mt-md-3">
              <Button type="submit" className="my-0 py-1 px-2 rounded-0">
                {t("Save Changes")}
              </Button>
            </div>
          </form>
        </div>
        <div className="col-12 col-md-3 d-flex flex-column justify-content-center align-items-start">
          <Image
            style={{ width: 100, height: 50, objectFit: "contain" }}
            src={AteejLogo}
          />
          <p className="my-0" style={{ color: Theme.colors.darkGrey }}>
            {t("Money back Guarantee")}
          </p>
        </div>
        <div className="col-12 border-top d-flex justify-content-between align-items-center mt-3">
          <h3>{t("Most relevant reviews")}</h3>
          <p
            className="my-0"
            style={{ color: Theme.colors.blue, cursor: "pointer" }}
          >
            See all {reviewsData?.length} reviews{" "}
            <IoIosArrowRoundDown size={25} />
          </p>
        </div>
        <div className="col-12">
          {reviewsData?.length &&
            reviewsData?.map((item, index) => {
              const navigateToUserProfile = () => {
                console.log("pressed!");
              };
              const size = 20;
              return (
                <div
                  className="d-flex justify-content-start align-items-end"
                  key={item?.id}
                >
                  <div className="px-2 w-25">
                    <Rating
                      readonly={true}
                      placeholderRating={item?.rating_score}
                      emptySymbol={
                        <AiFillStar size={size} color={Theme.colors.darkGrey} />
                      }
                      placeholderSymbol={
                        <AiFillStar size={size} color={Theme.colors.yellow} />
                      }
                      fullSymbol={
                        <AiFillStar size={size} color={Theme.colors.yellow} />
                      }
                    />
                    <p
                      className="my-2"
                      style={{ color: Theme.colors.darkGrey, fontSize: 12 }}
                    >
                      by{" "}
                      <span
                        onClick={() => navigateToUserProfile()}
                        style={{
                          color: Theme.colors.blue,
                          cursor: "pointer",
                        }}
                      >
                        {item?.created_by}
                      </span>
                    </p>
                    <p
                      className="my-2"
                      style={{ color: Theme.colors.darkGrey, fontSize: 12 }}
                    >
                      {item?.updated}
                    </p>
                  </div>
                  <div className="px-2 w-75">
                    <p className="my-2" style={{ fontSize: 18 }}>
                      {item?.title}
                    </p>
                    <p className="my-2" style={{ fontSize: 15 }}>
                      {item?.review}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {addedReviewAlert === true && (
        <SweetAlert
          type="success"
          title={t("Added review successful")}
          showConfirm={false}
        >
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => setAddedReviewAlert(false)}
              variant="primary mx-1"
              size="lg"
            >
              {t("Ok")}
            </Button>
          </div>
        </SweetAlert>
      )}
      {errorAddedReviewAlert === true && (
        <SweetAlert
          type="error"
          title={t("error with adding review")}
          showConfirm={false}
        >
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => setErrorAddedReviewAlert(false)}
              variant="primary mx-1"
              size="lg"
            >
              {t("Ok")}
            </Button>
          </div>
        </SweetAlert>
      )}
    </div>
  ) : (
    <div className="col-6 mx-auto">
      <form
        name="form"
        className="d-flex flex-column justify-content-center align-items-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p
          className="my-0"
          style={{ fontSize: 50, color: Theme.colors.darkGrey }}
        >
          {rateValue == undefined ? 0 : rateValue}
        </p>
        <Rating
          ref={register({
            required: "Required",
          })}
          initialRating={rateValue == undefined ? 0 : rateValue}
          onChange={(value) => setRateValue(value)}
          emptySymbol={<AiFillStar size={32} color={Theme.colors.darkGrey} />}
          placeholderSymbol={
            <AiFillStar size={32} color={Theme.colors.yellow} />
          }
          fullSymbol={<AiFillStar size={32} color={Theme.colors.yellow} />}
        />
        <div className="d-flex justify-content-between align-items-center w-100">
          <IoIosArrowBack
            onClick={() => toggleChangeQuestion("back")}
            style={{ cursor: "pointer" }}
            size={30}
          />
          <p className="my-0" style={{ color: Theme.colors.black }}>
            {questions[questionIndex]}
          </p>
          <IoIosArrowForward
            onClick={() => toggleChangeQuestion("next")}
            style={{ cursor: "pointer" }}
            size={30}
          />
        </div>
        <div className="d-flex justify-content-center w-100">
          {rateChoises?.map((item, index) => {
            const toggleAnswer = () => {
              questionIndex === 0
                ? changeFirstQuestionAnswer(item?.id)
                : questionIndex === 1
                ? changeSecondQuestionAnswer(item?.id)
                : changeThirdQuestionAnswer(item?.id);
            };
            return (
              <p
                key={item?.id}
                onClick={() => toggleAnswer()}
                className="rounded-pill my-1 mx-3 py-1 px-4"
                style={{
                  backgroundColor: Theme.colors.grey,
                  borderColor:
                    questionIndex === 0
                      ? state?.firstQuestionAnswer === item?.id
                        ? Theme.colors.darkGrey
                        : Theme.colors.grey
                      : questionIndex === 1
                      ? state?.secondQuestionAnswer === item?.id
                        ? Theme.colors.darkGrey
                        : Theme.colors.grey
                      : state?.thirdQuestionAnswer === item?.id
                      ? Theme.colors.darkGrey
                      : Theme.colors.grey,
                  bordrWidth: 2,
                  borderStyle: "solid",
                  cursor: "pointer",
                }}
              >
                {item.title}
              </p>
            );
          })}
        </div>
        <p
          className="text-center"
          style={{ fontSize: 12, color: Theme.colors.darkGrey }}
        >
          question {questionIndex + 1} of {questions.length}
        </p>
        <Form.Control
          name="title"
          ref={register({
            required: "Required",
          })}
          value={reviewTitle}
          maxlength="140"
          onChange={(e) => setReviewTitle(e?.target?.value)}
          className="my-2"
          type="text"
          placeholder={t("i recommend it")}
        />
        <Form.Control
          name="description"
          ref={register({
            required: "Required",
          })}
          value={reviewDescription}
          onChange={(e) => setReviewDescription(e?.target?.value)}
          className="my-2"
          maxlength="5000"
          as="textarea"
          rows={3}
          placeholder={t("Really great")}
        />
        <div className="d-flex flex-column w-100 align-items-start">
          <Form.File
            name="images"
            ref={register({
              required: "Required",
            })}
            multiple
            maxlength="4"
            onChange={(e) => uploadMultipleFiles(e)}
            className="my-2"
            id="exampleFormControlFile1"
            label={t("Add photo (up to 5 photos)")}
          />
          {showImagesError === true && (
            <p style={{ color: "red", fontSize: 12 }}>
              {`Cannot upload images more than ${MAX_LENGTH}`}
            </p>
          )}
          {showQuestionsError === true && (
            <p style={{ color: "red", fontSize: 12 }}>
              {`Please answer all questions`}
            </p>
          )}
        </div>
        <button type="submit" className="btn btn-primary rounded-0 my-2 w-100">
          {t("Save")}
        </button>
      </form>
      {addedReviewAlert === true && (
        <SweetAlert
          type="success"
          title={t("Added review successful")}
          showConfirm={false}
        >
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => setAddedReviewAlert(false)}
              variant="primary mx-1"
              size="lg"
            >
              {t("Ok")}
            </Button>
          </div>
        </SweetAlert>
      )}
      {errorAddedReviewAlert === true && (
        <SweetAlert
          type="error"
          title={t("error with adding review")}
          showConfirm={false}
        >
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => setErrorAddedReviewAlert(false)}
              variant="primary mx-1"
              size="lg"
            >
              {t("Ok")}
            </Button>
          </div>
        </SweetAlert>
      )}
    </div>
  );
};

export default RatingAndReviews;
