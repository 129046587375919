import React,{useState,useEffect,useContext} from "react";
import { Context as MainContext } from "../../../../Context/mainContext"
import { Context as AuthContext } from "../../../../Context/AuthContext"

const PricesList =({data,index,price})=>{
    const { state } = useContext(AuthContext);
    const [priceMultiplyQuantity,setPriceMultiplyQuantity]=useState(0);
    var title = data?.title?.substring(0, 30);
    return(
        <div className="w-100 d-flex justify-content-between border-bottom my-2">
            <p><span className="w-50" style={{fontSize: 12,overFlow: "auto",whiteSpace: "nowrap"}}><b>{title}</b></span> * <b>({data.availableQuantity})</b></p>
            <p><b>US ${price}</b></p>
        </div>
    )
}

export default PricesList;